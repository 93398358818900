import { createLoadable } from 'summer';

const mcgRoutes = [
  {
    component: createLoadable(() => import('./dashboard/DashboardMCG'), 'DashboardMCG'),
    path: '/mcg/dashboard',
  },
  {
    component: createLoadable(() => import('./grupoHistorico/GrupoHistoricoEditView'), 'GrupoHistoricoEditView'),
    path: '/mcg/grupoHistoricoSearchView/edit',
  },
  {
    component: createLoadable(() => import('./grupoHistorico/GrupoHistoricoSearchView'), 'GrupoHistoricoSearchView'),
    exact: true,
    path: '/mcg/grupoHistoricoSearchView',
  },
  {
    component: createLoadable(() => import('./historico/HistoricoEditView'), 'HistoricoEditView'),
    path: '/mcg/historicoSearchView/edit',
  },
  {
    component: createLoadable(() => import('./historico/HistoricoSearchView'), 'HistoricoSearchView'),
    exact: true,
    path: '/mcg/historicoSearchView',
  },
  {
    component: createLoadable(() => import('./customizacaoTermo/CustomizacaoTermoEditView'), 'CustomizacaoTermoEditView'),
    path: '/mcg/customizacaoTermoSearchView/edit',
  },
  {
    component: createLoadable(() => import('./customizacaoTermo/CustomizacaoTermoSearchView'), 'CustomizacaoTermoSearchView'),
    exact: true,
    path: '/mcg/customizacaoTermoSearchView',
  },
  {
    component: createLoadable(() => import('./doacaoPartidoPolitico/DoacaoPartidoPoliticoEditView'), 'DoacaoPartidoPoliticoEditView'),
    path: '/mcg/doacaoPartidoPoliticoSearchView/edit',
  },
  {
    component: createLoadable(() => import('./doacaoPartidoPolitico/DoacaoPartidoPoliticoSearchView'), 'DoacaoPartidoPoliticoSearchView'),
    exact: true,
    path: '/mcg/doacaoPartidoPoliticoSearchView',
  },
  {
    component: createLoadable(() => import('../ger/sociedadeContaParticipacao/SCPEditView'), 'SCPEditView'),
    path: '/mcg/scpSearchView/edit',
  },
  {
    component: createLoadable(() => import('../ger/sociedadeContaParticipacao/SCPSearchView'), 'SCPSearchView'),
    exact: true,
    path: '/mcg/scpSearchView',
  },
  {
    component: createLoadable(() => import('./livroFolha/LivroFolhaEditView'), 'LivroFolhaEditView'),
    path: '/mcg/livroFolhaEditView',
  },
  {
    component: createLoadable(() => import('./grupoCentroCusto/GrupoCentroCustoSearchView'), 'GrupoCentroCustoSearchView'),
    exact: true,
    path: '/mcg/grupoCentroCustoSearchView',
  },
  {
    component: createLoadable(() => import('./grupoCentroCusto/GrupoCentroCustoEditView'), 'GrupoCentroCustoEditView'),
    path: '/mcg/grupoCentroCustoSearchView/edit',
  },
  {
    component: createLoadable(() => import('./centroCusto/CentroCustoSearchView'), 'CentroCustoSearchView'),
    exact: true,
    path: '/mcg/centroCustoSearchView',
  },
  {
    component: createLoadable(() => import('./centroCusto/CentroCustoEditView'), 'CentroCustoEditView'),
    path: '/mcg/centroCustoSearchView/edit',
  },
  {
    component: createLoadable(() => import('./notaExplicativa/NotaExplicativaReferenciaSearchView'), 'NotaExplicativaReferenciaSearchView'),
    exact: true,
    path: '/mcg/notaExplicativaReferenciaSearchView',
  },
  {
    component: createLoadable(() => import('./notaExplicativa/NotaExplicativaReferenciaEditView'), 'NotaExplicativaReferenciaEditView'),
    path: '/mcg/notaExplicativaReferenciaSearchView/edit',
  },
  {
    component: createLoadable(() => import('./integracao/IntegracaoContabilProcessView'), 'integracaoContabilProcessView'),
    path: '/mcg/integracaoContabilProcessView',
  },
  {
    component: createLoadable(
      () => import('./importacaoLancamentoPadraoCSV/ImportacaoLancamentoPadraoCSVProcessView'),
      'ImportacaoLancamentoPadraoCSVProcessView'
    ),
    path: '/mcg/importacaoLancamentoPadraoCSVProcessView',
  },
  {
    component: createLoadable(() => import('./importacaoLancamentosCSV/ImportacaoLancamentosCSVProcessView'), 'ImportacaoLancamentosCSVProcessView'),
    path: '/mcg/importacaoLancamentosCSVProcessView',
  },
  {
    component: createLoadable(() => import('./encerrarContasResultado/EncerrarContasResultadoProcessView'), 'EncerrarContasResultadoProcessView'),
    path: '/mcg/encerrarContasResultadoProcessView',
  },
  {
    component: createLoadable(() => import('./report/balanceteNormal/BalanceteNormalReportView'), 'BalanceteNormalReportView'),
    path: '/mcg/balanceteNormalReportView',
  },
  {
    component: createLoadable(
      () => import('./report/balanceteNormal/balanceteNormalAnual/BalanceteNormalAnualReportView'),
      'BalanceteNormalAnualReportView'
    ),
    path: '/mcg/balanceteNormalAnualReportView',
  },
  {
    component: createLoadable(() => import('./report/balanceteCaixa/BalanceteCaixaReportView'), 'BalanceteCaixaReportView'),
    path: '/mcg/balanceteCaixaReportView',
  },
  {
    component: createLoadable(() => import('./report/balanceteComparativo/BalanceteComparativoReportView'), 'BalanceteComparativoReportView'),
    path: '/mcg/balanceteComparativoReportView',
  },
  {
    component: createLoadable(() => import('./report/balancoAbertura/BalancoAberturaReportView'), 'BalancoAberturaReportView'),
    path: '/mcg/balancoAberturaReportView',
  },
  {
    component: createLoadable(() => import('./report/contaFluxoCaixa/ContaFluxoCaixaReportView'), 'ContaFluxoCaixaReportView'),
    path: '/mcg/contaFluxoCaixaReportView',
  },
  {
    component: createLoadable(() => import('./report/caixaNormal/CaixaNormalReportView'), 'CaixaNormalReportView'),
    path: '/mcg/caixaNormalReportView',
  },
  {
    component: createLoadable(() => import('./report/caixaSimplificado/CaixaSimplificadoReportView'), 'CaixaSimplificadoReportView'),
    path: '/mcg/caixaSimplificadoReportView',
  },
  {
    component: createLoadable(() => import('./report/boletimDiarioCaixa/BoletimDiarioCaixaReportView'), 'BoletimDiarioCaixaReportView'),
    path: '/mcg/boletimDiarioCaixaReportView',
  },
  {
    component: createLoadable(() => import('./report/lalurLacsParteA/LalurLacsParteAReportView'), 'LalurLacsParteAReportView'),
    path: '/mcg/lalurLacsParteAReportView',
  },
  {
    component: createLoadable(() => import('./report/lalurLacsParteB/LalurLacsParteBReportView'), 'LalurLacsParteBReportView'),
    path: '/mcg/lalurLacsParteBReportView',
  },
  {
    component: createLoadable(() => import('./report/razaoNormal/RazaoNormalReportView'), 'RazaoNormalReportView'),
    path: '/mcg/razaoNormalReportView',
  },
  {
    component: createLoadable(() => import('./report/razaoCentroCusto/RazaoCentroCustoReportView'), 'RazaoCentroCustoReportView'),
    path: '/mcg/razaoCentroCustoReportView',
  },
  {
    component: createLoadable(() => import('./report/diario/DiarioReportView'), 'DiarioReportView'),
    path: '/mcg/diarioReportView',
  },
  {
    component: createLoadable(
      () => import('./report/demonstracaoMutacoesPatrimonioLiquido/DemonstracaoMutacoesPatrimonioLiquidoReportView'),
      'DemonstracaoMutacoesPatrimonioLiquidoReportView'
    ),
    path: '/mcg/demonstracaoMutacoesPatrimonioLiquidoReportView',
  },
  {
    component: createLoadable(
      () => import('./report/demonstracaoValorAdicionado/DemonstracaoValorAdicionadoReportView'),
      'DemonstracaoValorAdicionadoReportView'
    ),
    path: '/mcg/demonstracaoValorAdicionadoReportView',
  },
  {
    component: createLoadable(() => import('./report/razaoEventual/RazaoEventualReportView'), 'RazaoEventualReportView'),
    path: '/mcg/razaoEventualReportView',
  },
  {
    component: createLoadable(() => import('./report/lancamento/LancamentoReportView'), 'LancamentoReportView'),
    path: '/mcg/lancamentoReportView',
  },
  {
    component: createLoadable(() => import('./report/receitaDespesas/ReceitaDespesasReportView'), 'ReceitaDespesasReportView'),
    path: '/mcg/receitaDespesasReportView',
  },
  {
    component: createLoadable(() => import('./report/declaracaoVeracidade/DeclaracaoVeracidadeReportView'), 'DeclaracaoVeracidadeReportView'),
    path: '/mcg/declaracaoVeracidadeReportView',
  },
  {
    component: createLoadable(() => import('./report/conciliacaoBancaria/ConciliacaoBancariaReportView'), 'ConciliacaoBancariaReportView'),
    path: '/mcg/conciliacaoBancariaReportView',
  },
  {
    component: createLoadable(() => import('./report/centroCusto/CentroCustoReportView'), 'CentroCustoReportView'),
    path: '/mcg/centroCustoReportView',
  },
  {
    component: createLoadable(() => import('./report/historico/HistoricoReportView'), 'HistoricoReportView'),
    path: '/mcg/historicoReportView',
  },
  {
    component: createLoadable(() => import('./report/lancamentoPadrao/LancamentosPadraoReportView'), 'LancamentosPadraoReportView'),
    path: '/mcg/lancamentosPadraoReportView',
  },
  {
    component: createLoadable(() => import('./report/balanceteCentroCusto/BalanceteCentroCustoReportView'), 'BalanceteCentroCustoReportView'),
    path: '/mcg/balanceteCentroCustoReportView',
  },
  {
    component: createLoadable(
      () => import('./report/contaResultadoClassificada/ContaResultadoClassificadaReportView'),
      'ContaResultadoClassificadaReportView'
    ),
    path: '/mcg/contaResultadoClassificadaReportView',
  },
  {
    component: createLoadable(
      () => import('./report/demonstracaoLucroPrejuizo/DemonstrativoLucroPrejuizoReportView'),
      'DemonstrativoLucroPrejuizoReportView'
    ),
    path: '/mcg/demonstrativoLucroPrejuizoReportView',
  },
  {
    component: createLoadable(() => import('./report/historicoPadrao/HistoricoPadraoReportView'), 'HistoricoPadraoReportView'),
    path: '/mcg/historicoPadraoReportView',
  },
  {
    component: createLoadable(() => import('./report/analiseFinanceira/AnaliseFinanceiraReportView'), 'AnaliseFinanceiraReportView'),
    path: '/mcg/analiseFinanceiraReportView',
  },
  {
    component: createLoadable(() => import('./report/demonstracaoResultado/DemonstracaoResultadoReportView'), 'DemonstracaoResultadoReportView'),
    path: '/mcg/demonstracaoResultadoReportView',
  },
  {
    component: createLoadable(
      () => import('./report/demonstracaoResultado/demonstracaoResultadoAnual/DemonstracaoResultadoAnualReportView'),
      'DemonstracaoResultadoAnualReportView'
    ),
    path: '/mcg/demonstracaoResultadoAnualReportView',
  },
  {
    component: createLoadable(
      () => import('./report/planoContaRelacionamento/PlanoContaRelacionamentoReportView'),
      'PlanoContaRelacionamentoReportView'
    ),
    path: '/mcg/planoContaRelacionamentoReportView',
  },
  {
    component: createLoadable(() => import('./report/cartaResponsabilidade/CartaResponsabilidadeReportView'), 'CartaResponsabilidadeReportView'),
    path: '/mcg/cartaResponsabilidadeReportView',
  },
  {
    component: createLoadable(() => import('./fechamentoRestauracaoMCG/FechamentoRestauracaoMCGProcessView'), 'FechamentoRestauracaoMCGProcessView'),
    path: '/mcg/fechamentoRestauracaoMCGProcessView',
  },
  {
    component: createLoadable(
      () => import('./report/termoAberturaEncerramento/TermoAberturaEncerramentoReportView'),
      'TermoAberturaEncerramentoReportView'
    ),
    path: '/mcg/termoAberturaEncerramentoReportView',
  },
  {
    component: createLoadable(() => import('./planoConta/PlanoContaSearchView'), 'PlanoContaSearchView'),
    exact: true,
    path: '/mcg/planoContaSearchView',
  },
  {
    component: createLoadable(
      () => import('./planoContaMcgReferencialInstituicao/PlanoContaMCGReferencialInstituicaoEditView'),
      'PlanoContaMCGReferencialInstituicaoEditView'
    ),
    exact: true,
    path: '/mcg/planoContaMCGReferencialInstituicaoSearchView/edit',
  },
  {
    component: createLoadable(() => import('./lalurParteB/LalurParteBSearchView'), 'LalurParteBSearchView'),
    exact: true,
    path: '/mcg/lalurParteBSearchView',
  },
  {
    component: createLoadable(() => import('./lalurParteB/LalurParteBEditView'), 'LalurParteBEditView'),
    exact: true,
    path: '/mcg/lalurParteBSearchView/edit',
  },
  {
    component: createLoadable(() => import('./planoContaReferencial/PlanoContaReferencialSearchView'), 'PlanoContaReferencialSearchView'),
    exact: true,
    path: '/mcg/planoContaReferencialSearchView',
  },
  {
    component: createLoadable(() => import('./verificarErros/VerificarErroMCGProcessView'), 'VerificarErroMCGProcessView'),
    exact: true,
    path: '/mcg/verificarErroMCGProcessView',
  },
  {
    component: createLoadable(() => import('./planoContaReferencial/PlanoContaReferencialEditView'), 'PlanoContaReferencialEditView'),
    exact: true,
    path: '/mcg/planoContaReferencialSearchView/edit',
  },
  {
    component: createLoadable(
      () => import('./planoContaMcgReferencialInstituicao/PlanoContaMCGReferencialInstituicaoSearchView'),
      'PlanoContaMCGReferencialInstituicaoSearchView'
    ),
    exact: true,
    path: '/mcg/planoContaMCGReferencialInstituicaoSearchView',
  },
  {
    component: createLoadable(() => import('./importacaoHistoricoCSV/ImportacaoHistoricoCSVProcessView'), 'ImportacaoHistoricoCSVProcessView'),
    exact: true,
    path: '/mcg/importacaoHistoricoCSVProcessView',
  },
  {
    component: createLoadable(() => import('./importacaoPlanoContaCSV/ImportacaoPlanoContaCSVProcessView'), 'ImportacaoPlanoContaCSVProcessView'),
    exact: true,
    path: '/mcg/importacaoPlanoContaCSVProcessView',
  },
  {
    component: createLoadable(() => import('./importacaoHistorico/ImportacaoHistoricoProcessView'), 'ImportacaoHistoricoProcessView'),
    exact: true,
    path: '/mcg/importacaoHistoricoProcessView',
  },
  {
    component: createLoadable(() => import('./report/balancoPatrimonial/BalancoPatrimonialReportView'), 'BalancoPatrimonialReportView'),
    path: '/mcg/balancoPatrimonialReportView',
  },
  {
    component: createLoadable(() => import('./importacaoPlanoConta/ImportacaoPlanoContaProcessView'), 'ImportacaoPlanoContaProcessView'),
    exact: true,
    path: '/mcg/importacaoPlanoContaProcessView',
  },
  {
    component: createLoadable(
      () => import('./transferenciaLancamentoConta/TransferenciaLancamentoContaProcessView'),
      'TransferenciaLancamentoContaProcessView'
    ),
    exact: true,
    path: '/mcg/transferenciaLancamentoContaProcessView',
  },
  {
    component: createLoadable(() => import('./report/socioParticipacao/SocioParticipacaoReportView'), 'SocioParticipacaoReportView'),
    exact: true,
    path: '/mcg/socioParticipacaoReportView',
  },
  {
    component: createLoadable(() => import('./lancamentoPadrao/LancamentoPadraoEditView'), 'LancamentoPadraoEditView'),
    path: '/mcg/lancamentoPadraoSearchView/edit',
  },
  {
    component: createLoadable(() => import('./lancamentoPadrao/LancamentoPadraoSearchView'), 'LancamentoPadraoSearchView'),
    exact: true,
    path: '/mcg/lancamentoPadraoSearchView',
  },
  {
    component: createLoadable(() => import('./lancamentoMCGConfig/LancamentoMCGConfigEditView'), 'LancamentoMCGConfigEditView'),
    path: '/mcg/lancamentoMCGConfigSearchView/edit',
  },
  {
    component: createLoadable(() => import('./lancamentoMCGConfig/LancamentoMCGConfigSearchView'), 'LancamentoMCGConfigSearchView'),
    exact: true,
    path: '/mcg/lancamentoMCGConfigSearchView',
  },
  {
    component: createLoadable(() => import('./configEmpresaMCG/ConfigEmpresaMCGEditView'), 'ConfigEmpresaMCGEditView'),
    exact: true,
    path: '/mcg/configEmpresaMCGEditView',
  },
  {
    component: createLoadable(() => import('./analiseFinanceira/AnaliseFinanceiraConfigView'), 'AnaliseFinanceiraConfigView'),
    exact: true,
    path: '/mcg/analiseFinanceiraConfigView',
  },
  {
    component: createLoadable(() => import('./report/planoContaRef/PlanoContaRefReportView'), 'PlanoContaReferencialReportView'),
    path: '/mcg/planoContaRefReportView',
  },
  {
    component: createLoadable(() => import('./report/saldosPlanoConta/SaldosPlanoContaReportView'), 'SaldosPlanoContaReportView'),
    path: '/mcg/saldosPlanoContaReportView',
  },
  {
    component: createLoadable(() => import('./report/planoConta/PlanoContaReportView'), 'PlanoContaReportView'),
    path: '/mcg/planoContaReportView',
  },
  {
    component: createLoadable(() => import('./spedECFExportacao/SpedECFExportacaoProcessView'), 'SpedECFExportacaoProcessView'),
    exact: true,
    path: '/mcg/spedECFExportacaoProcessView',
  },
  {
    component: createLoadable(() => import('./geracaoSpedEcd/GeracaoSpedECDProcessView'), 'GeracaoSpedECDProcessView'),
    path: '/mcg/geracaoSpedECDProcessView',
  },
  {
    component: createLoadable(() => import('./lalurLacsConfig/LalurLacsConfigProcessView'), 'LalurLacsConfigProcessView'),
    exact: true,
    path: '/mcg/lalurLacsConfigProcessView',
  },
  {
    component: createLoadable(
      () => import('./lucroPrejuizoClassificacao/LucroPrejuizoClassificacaoSearchView'),
      'LucroPrejuizoClassificacaoSearchView'
    ),
    exact: true,
    path: '/mcg/lucroPrejuizoClassificacaoSearchView',
  },
  {
    component: createLoadable(() => import('./lucroPrejuizo/LucroPrejuizoEditView'), 'LucroPrejuizoEditView'),
    path: '/mcg/lucroPrejuizoSearchView/edit',
  },
  {
    component: createLoadable(() => import('./lucroPrejuizo/LucroPrejuizoSearchView'), 'LucroPrejuizoSearchView'),
    exact: true,
    path: '/mcg/lucroPrejuizoSearchView',
  },

  {
    component: createLoadable(() => import('./configECFIrpjCsll/ConfigECFIrpjCsllEditView'), 'ConfigECFIrpjCsllEditView'),
    path: '/mcg/configECFIrpjCsllSearchView/edit',
  },
  {
    component: createLoadable(() => import('./configECFIrpjCsll/ConfigECFIrpjCsllSearchView'), 'ConfigECFIrpjCsllSearchView'),
    exact: true,
    path: '/mcg/configECFIrpjCsllSearchView',
  },
  {
    component: createLoadable(() => import('./configECFImuneIsenta/ConfigECFImuneIsentaEditView'), 'ConfigECFImuneIsentaEditView'),
    path: '/mcg/configECFImuneIsentaSearchView/edit',
  },
  {
    component: createLoadable(() => import('./configECFImuneIsenta/ConfigECFImuneIsentaSearchView'), 'ConfigECFImuneIsentaSearchView'),
    exact: true,
    path: '/mcg/configECFImuneIsentaSearchView',
  },
  {
    component: createLoadable(() => import('./configECFY612/ConfigECFY612EditView'), 'ConfigECFY612EditView'),
    path: '/mcg/configECFY612SearchView/edit',
  },
  {
    component: createLoadable(() => import('./configECFY612/ConfigECFY612SearchView'), 'ConfigECFY612SearchView'),
    exact: true,
    path: '/mcg/configECFY612SearchView',
  },
  {
    component: createLoadable(() => import('./saldoAnteriorCentroCusto/SaldoAnteriorCentroCustoSearchView'), 'SaldoAnteriorCentroCustoSearchView'),
    exact: true,
    path: '/mcg/saldoAnteriorCentroCustoSearchView',
  },
  {
    component: createLoadable(() => import('./saldoAnteriorCentroCusto/SaldoAnteriorCentroCustoEditView'), 'SaldoAnteriorCentroCustoEditView'),
    exact: true,
    path: '/mcg/saldoAnteriorCentroCustoSearchView/edit',
  },
  {
    component: createLoadable(() => import('./saldoAnteriorPlanoConta/SaldoAnteriorPlanoContaSearchView'), 'SaldoAnteriorPlanoContaSearchView'),
    exact: true,
    path: '/mcg/saldoAnteriorPlanoContaSearchView',
  },
  {
    component: createLoadable(() => import('./saldoAnteriorPlanoConta/SaldoAnteriorPlanoContaEditView'), 'SaldoAnteriorPlanoContaEditView'),
    exact: true,
    path: '/mcg/saldoAnteriorPlanoContaSearchView/edit',
  },
  {
    component: createLoadable(
      () => import('./report/contaFluxoCaixaIndireto/ContaFluxoCaixaIndiretoReportView'),
      'ContaFluxoCaixaIndiretoReportView'
    ),
    exact: true,
    path: '/mcg/contaFluxoCaixaIndiretoReportView',
  },
  {
    component: createLoadable(() => import('./grupoPlanoConta/GrupoPlanoContaEditView'), 'GrupoPlanoContaEditView'),
    path: '/mcg/grupoPlanoContaSearchView/edit',
  },
  {
    component: createLoadable(() => import('./grupoPlanoConta/GrupoPlanoContaSearchView'), 'GrupoPlanoContaSearchView'),
    exact: true,
    path: '/mcg/grupoPlanoContaSearchView',
  },
  {
    component: createLoadable(() => import('./grupoPlanoConta/GrupoPlanoContaConfigEditView'), 'GrupoPlanoContaConfigEditView'),
    exact: true,
    path: '/mcg/grupoPlanoContaConfigEditView',
  },
  {
    component: createLoadable(() => import('./lancamentoSaldo/LancamentoSaldoSearchView'), 'LancamentoSaldoSearchView'),
    exact: true,
    path: '/mcg/lancamentoSaldoSearchView',
  },
  {
    component: createLoadable(() => import('./contaFluxoCaixaIndireto/ContaFluxoCaixaIndiretoTreeView'), 'ContaFluxoCaixaIndiretoTreeView'),
    exact: true,
    path: '/mcg/contaFluxoCaixaIndiretoTreeView',
  },
  {
    component: createLoadable(() => import('./mutacaoPatrimonioLiquido/MutacaoPatrimonioLiquidoTreeView'), 'MutacaoPatrimonioLiquidoTreeView'),
    exact: true,
    path: '/mcg/mutacaoPatrimonioLiquidoTreeView',
  },
  {
    component: createLoadable(
      () => import('./demonstracaoValorAdicionado/DemonstracaoValorAdicionadoTreeView'),
      'DemonstracaoValorAdicionadoTreeView'
    ),
    exact: true,
    path: '/mcg/demostracaoValorAdicionadoTreeView',
  },
  {
    component: createLoadable(() => import('./contaResultadoMCG/ContaResultadoMCGTreeView'), 'ContaResultadoMCGTreeView'),
    exact: true,
    path: '/mcg/contaResultadoMCGTreeView',
  },
  {
    component: createLoadable(() => import('./contaFluxoCaixa/ContaFluxoCaixaDiretoTreeView'), 'ContaFluxoCaixaDiretoTreeView'),
    exact: true,
    path: '/mcg/contaFluxoCaixaDiretoTreeView',
  },
  {
    component: createLoadable(() => import('./planoConta/PlanoContaEditView'), 'PlanoContaEditView'),
    path: '/mcg/planoContaSearchView/edit',
  },
  {
    component: createLoadable(() => import('./planoContaLalurA/PlanoContaLalurASearchView'), 'PlanoContaLalurASearchView'),
    exact: true,
    path: '/mcg/planoContaLalurASearchView',
  },
  {
    component: createLoadable(() => import('./planoContaLalurA/PlanoContaLalurAEditView'), 'PlanoContaLalurAEditView'),
    path: '/mcg/planoContaLalurASearchView/edit',
  },
  {
    component: createLoadable(() => import('./quadroSocietario/QuadroSocietarioSearchView'), 'QuadroSocietarioSearchView'),
    exact: true,
    path: '/mcg/quadroSocietarioSearchView',
  },
  {
    component: createLoadable(() => import('./quadroSocietario/QuadroSocietarioEditView'), 'QuadroSocietarioEditView'),
    path: '/mcg/quadroSocietarioSearchView/edit',
  },
  {
    component: createLoadable(() => import('./importacaoLancamentoMCG/ImportacaoLancamentoMCGView'), 'ImportacaoLancamentoMCGView'),
    path: '/mcg/importacaoLancamentoMCGView',
  },
  {
    component: createLoadable(() => import('./importacaoSpedContabil/ImportacaoSpedContabilProcessView'), 'ImportacaoSpedContabilProcessView'),
    path: '/mcg/importacaoSpedContabilProcessView',
  },
  {
    component: createLoadable(() => import('./exportacaoManadMCG/ExportacaoManadMCGProcessView'), 'ExportacaoManadMCGProcessView'),
    path: '/mcg/exportacaoManadMCGProcessView',
  },
  {
    component: createLoadable(() => import('./evolucaoContaContabilGraf/EvolucaoContaContabilGrafView'), 'EvolucaoContaContabilGrafView'),
    path: '/mcg/evolucaoContaContabilGrafView',
  },
  {
    component: createLoadable(() => import('./composicaoReceitaDespesasGraf/ComposicaoReceitaDespesasGrafView'), 'ComposicaoReceitaDespesasGrafView'),
    path: '/mcg/composicaoReceitaDespesasGrafView',
  },
  {
    component: createLoadable(() => import('./exportacaoFcont/ExportacaoFcontProcessView'), 'ExportacaoFcontProcessView'),
    path: '/mcg/exportacaoFcontProcessView',
  },
  {
    component: createLoadable(
      () => import('./historicoExtratoPlanoConta/HistoricoExtratoPlanoContaSearchView'),
      'HistoricoExtratoPlanoContaSearchView'
    ),
    exact: true,
    path: '/mcg/historicoExtratoPlanoContaSearchView',
  },
  {
    component: createLoadable(() => import('./historicoExtratoPlanoConta/HistoricoExtratoPlanoContaEditView'), 'HistoricoExtratoPlanoContaEditView'),
    exact: true,
    path: '/mcg/historicoExtratoPlanoContaSearchView/edit',
  },
  {
    component: createLoadable(() => import('./bancoExtratoPlanoConta/BancoExtratoPlanoContaSearchView'), 'BancoExtratoPlanoContaSearchView'),
    exact: true,
    path: '/mcg/bancoExtratoPlanoContaSearchView',
  },
  {
    component: createLoadable(() => import('./bancoExtratoPlanoConta/BancoExtratoPlanoContaEditView'), 'BancoExtratoPlanoContaEditView'),
    exact: true,
    path: '/mcg/bancoExtratoPlanoContaSearchView/edit',
  },
  {
    component: createLoadable(() => import('./importacaoExtratoBancario/ImportacaoExtratoBancarioView'), 'ImportacaoExtratoBancarioView'),
    path: '/mcg/importacaoExtratoBancarioView',
  },
  {
    component: createLoadable(() => import('./conciliacaoBancaria/ConciliacaoBancariaView'), 'ConciliacaoBancariaView'),
    path: '/mcg/conciliacaoBancariaView',
  },
  {
    component: createLoadable(() => import('./lancamentoSaldo/LancamentoSaldoEditView'), 'LancamentoSaldoEditView'),
    path: '/mcg/lancamentoSaldoSearchView/edit',
  },
  {
    component: createLoadable(() => import('./conciliacaoContas/ConciliacaoContasView'), 'ConciliacaoContasView'),
    path: '/mcg/conciliacaoContasView',
  },
  {
    component: createLoadable(() => import('./lalurLacsApuracao/LalurLacsApuracaoProcessView'), 'LalurLacsApuracaoProcessView'),
    path: '/mcg/lalurLacsApuracaoProcessView',
  },
  {
    component: createLoadable(() => import('./lacsParteB/LacsParteBSearchView'), 'LacsParteBSearchView'),
    exact: true,
    path: '/mcg/lacsParteBSearchView',
  },
  {
    component: createLoadable(() => import('./lacsParteB/LacsParteBEditView'), 'LacsParteBEditView'),
    path: '/mcg/lacsParteBSearchView/edit',
  },
  {
    component: createLoadable(() => import('./saldoAnteriorRegistroECD/SaldoAnteriorRegistroECDSearchView'), 'SaldoAnteriorRegistroECDSearchView'),
    exact: true,
    path: '/mcg/saldoAnteriorRegistroECDSearchView',
  },
  {
    component: createLoadable(() => import('./saldoAnteriorRegistroECD/SaldoAnteriorRegistroECDEditView'), 'SaldoAnteriorRegistroECDEditView'),
    path: '/mcg/saldoAnteriorRegistroECDSearchView/edit',
  },
  {
    component: createLoadable(() => import('./imovelRural/ImovelRuralSearchView'), 'ImovelRuralSearchView'),
    exact: true,
    path: '/mcg/imovelRuralSearchView',
  },
  {
    component: createLoadable(() => import('./imovelRural/ImovelRuralEditView'), 'ImovelRuralEditView'),
    path: '/mcg/imovelRuralSearchView/edit',
  },
  {
    component: createLoadable(() => import('./terceiro/TerceiroSearchView'), 'TerceiroSearchView'),
    exact: true,
    path: '/mcg/terceiroSearchView',
  },
  {
    component: createLoadable(() => import('./terceiro/TerceiroEditView'), 'TerceiroEditView'),
    path: '/mcg/terceiroSearchView/edit',
  },
  {
    component: createLoadable(() => import('./lcdprExportacao/LcdprExportacaoProcessView'), 'LcdprExportacaoProcessView'),
    path: '/mcg/lcdprExportacaoProcessView',
  },
  {
    component: createLoadable(
      () => import('./lancamentoSaldoSimplificado/LancamentoSaldoSimplificadoProcessView'),
      'LancamentoSaldoSimplificadoProcessView'
    ),
    path: '/mcg/lancamentoSaldoSimplificadoProcessView',
  },
  {
    component: createLoadable(() => import('./report/balancoCentroCusto/BalancoCentroCustoReportView'), 'BalancoCentroCustoReportView'),
    path: '/mcg/balancoCentroCustoReportView',
  },
  {
    component: createLoadable(
      () => import('./report/demonstracaoResultadoCentroCusto/DemonstracaoResultadoCentroCustoReportView'),
      'DemonstracaoResultadoCentroCustoReportView'
    ),
    path: '/mcg/demonstracaoResultadoCentroCustoReportView',
  },
  {
    component: createLoadable(
      () => import('./report/analiseFinanceiraCentroCusto/AnaliseFinanceiraCentroCustoReportView'),
      'AnaliseFinanceiraCentroCustoReportView'
    ),
    path: '/mcg/analiseFinanceiraCentroCustoReportView',
  },
  {
    component: createLoadable(() => import('./report/diarioCentroCusto/DiarioCentroCustoReportView'), 'DiarioCentroCustoReportView'),
    path: '/mcg/diarioCentroCustoReportView',
  },
  {
    component: createLoadable(() => import('./configComposicaoCustos/ConfigComposicaoCustosEditView'), 'ConfigComposicaoCustosEditView'),
    path: '/mcg/configComposicaoCustosSearchView/edit',
  },
  {
    component: createLoadable(() => import('./configComposicaoCustos/ConfigComposicaoCustosSearchView'), 'ConfigComposicaoCustosSearchView'),
    exact: true,
    path: '/mcg/configComposicaoCustosSearchView',
  },
  {
    component: createLoadable(() => import('./vincularContasMCG/VincularContasComposicaoCustosTreeView'), 'VincularContasComposicaoCustosTreeView'),
    exact: true,
    path: '/mcg/vincularContasComposicaoCustosTreeView',
  },
  {
    component: createLoadable(() => import('./integracao/IntegracaoContabilConfigEditView'), 'IntegracaoContabilConfigEditView'),
    exact: true,
    path: '/mcg/integracaoContabilConfigEditView',
  },
  {
    component: createLoadable(() => import('./configECFImuneIsenta/ECFImuneIsentaGridView'), 'ECFImuneIsentaGridView'),
    exact: true,
    path: '/mcg/ecfImuneIsentaGridView',
  },
  {
    component: createLoadable(() => import('./balanceteDinamico/BalanceteDinamicoProcessView'), 'BalanceteDinamicoProcessView'),
    exact: true,
    path: '/mcg/balanceteDinamicoProcessView',
  },
];

export default mcgRoutes;
