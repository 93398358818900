import { AxiosInstance, AxiosResponse } from 'axios';
import { AxiosParam, create, send } from 'summer';
import { ReportBasicValues } from '../models/dtos/ger/reportBasicValues';
import { LOCAL_STORAGE_SOCKET_HASH } from '../utilities/constantes';
import { GuiaGrcsuRV } from '../views/pages/mfp/report/guiaGrcsu/guiaGrcsuRV';

function getService(addGzip = false): AxiosInstance {
  return create('/report', addGzip);
}

export function download(uid: string, param: AxiosParam<void>) {
  send(getService().get<void>(`/download/${uid}`), param);
}

export function fileDownload(uid: string, param: AxiosParam<AxiosResponse>) {
  getService(false)
    .get<void>(`/fileDownload${uid}`, { responseType: 'blob' })
    .then((response: AxiosResponse) => {
      param.thenFunction(response);
    });
}

export function fileOpen(uid: string) {
  const code = btoa(uid);
  const url = `${getService().defaults.baseURL}/fileOpen?code=${code}`;
  window.open(url, '_blank');
}

export function fileDownloadRemessa(uid: string, param: AxiosParam<AxiosResponse>) {
  getService(false)
    .get<void>(`/fileDownloadRemessa${uid}`, { responseType: 'blob' })
    .then((response: AxiosResponse) => {
      param.thenFunction(response);
    });
}

export function gerarReport(parameter: ReportBasicValues, reportName: string, param: AxiosParam<string>) {
  const config = {
    headers: {
      socketHash: sessionStorage.getItem(LOCAL_STORAGE_SOCKET_HASH),
    },
  };
  send(getService(false).post<string>(`/${reportName}`, parameter, config), param);
}

export function printGuiaGrcsu(guiaGrcsu: GuiaGrcsuRV, param: AxiosParam<string>) {
  send(getService().post<string>(`/printGuiaGrcsu/`, guiaGrcsu), param);
}
