export const ENVIO_EMAIL = {
  label:
    'Os e-mails foram adicionados à fila e logo serão enviados. Você pode acompanhar o andamento do envio <ToastRedirect path="/ger/gerenteEmailSearchView" label="Clicando aqui" />',
  value: 'ENVIO_EMAIL',
};
export const ENVIO_CONNECT = { label: 'Relatório enviado com sucesso para o Connect.', value: 'ENVIO_CONNECT' };
export const ENVIO_EMAIL_CONNECT = {
  label:
    'Relatório enviado com sucesso para o Connect e adicionado à fila de envio de e-mails. Você pode acompanhar o andamento do envio <ToastRedirect path="/ger/gerenteEmailSearchView" label="Clicando aqui" />',
  value: 'ENVIO_EMAIL_CONNECT',
};

export const REPORT_STATUS_ENUM_VALUES = [ENVIO_EMAIL, ENVIO_CONNECT, ENVIO_EMAIL_CONNECT];
