import { createLoadable } from 'summer';

const mlfRoutes = [
  {
    component: createLoadable(() => import('./dashboard/DashboardMLF'), 'DashboardMLF'),
    path: '/mlf/dashboard',
  },
  {
    component: createLoadable(() => import('../ger/grupoProduto/GrupoProdutoEditView'), 'GrupoProdutoEditView'),
    path: '/mlf/grupoProdutoSearchView/edit',
  },
  {
    component: createLoadable(() => import('../ger/grupoProduto/GrupoProdutoSearchView'), 'GrupoProdutoSearchView'),
    exact: true,
    path: '/mlf/grupoProdutoSearchView',
  },
  {
    component: createLoadable(() => import('./grupoTipoProduto/GrupoTipoProdutoEditView'), 'GrupoTipoProdutoEditView'),
    path: '/mlf/grupoTipoProdutoSearchView/edit',
  },
  {
    component: createLoadable(
      () => import('./configLancamentoConhecimentoTransporte/ConfigLancamentoConhecimentoTransporteSearchView'),
      'ConfigLancamentoConhecimentoTransporteSearchView'
    ),
    exact: true,
    path: '/mlf/configLancamentoConhecimentoTransporteSearchView',
  },
  {
    component: createLoadable(
      () => import('./configLancamentoConhecimentoTransporte/ConfigLancamentoConhecimentoTransportEditView'),
      'ConfigLancamentoConhecimentoTransportEditView'
    ),
    path: '/mlf/configLancamentoConhecimentoTransporteSearchView/edit',
  },
  {
    component: createLoadable(() => import('./configLancamentoReducaoZ/ConfigLancamentoReducaoZSearchView'), 'ConfigLancamentoReducaoZSearchView'),
    exact: true,
    path: '/mlf/configLancamentoReducaoZSearchView',
  },
  {
    component: createLoadable(() => import('./configLancamentoReducaoZ/ConfigLancamentoReducaoZEditView'), 'ConfigLancamentoReducaoZEditView'),
    path: '/mlf/configLancamentoReducaoZSearchView/edit',
  },
  {
    component: createLoadable(
      () => import('./configLacamentoEntradaSaida/ConfigLancamentoEntradaSaidaSearchView'),
      'ConfigLancamentoEntradaSaidaSearchView'
    ),
    exact: true,
    path: '/mlf/configLancamentoEntradaSaidaSearchView',
  },
  {
    component: createLoadable(
      () => import('./configLacamentoEntradaSaida/ConfigLancamentoEntradaSaidaEditView'),
      'ConfigLancamentoEntradaSaidaEditView'
    ),
    path: '/mlf/configLancamentoEntradaSaidaSearchView/edit',
  },
  {
    component: createLoadable(() => import('./grupoTipoProduto/GrupoTipoProdutoSearchView'), 'GrupoTipoProdutoSearchView'),
    exact: true,
    path: '/mlf/grupoTipoProdutoSearchView',
  },
  {
    component: createLoadable(
      () => import('./grupoTipoMovimentacaoInterna/GrupoTipoMovimentacaoInternaEditView'),
      'GrupoTipoMovimentacaoInternaEditView'
    ),
    path: '/mlf/grupoTipoMovimentacaoInternaSearchView/edit',
  },
  {
    component: createLoadable(
      () => import('./grupoTipoMovimentacaoInterna/GrupoTipoMovimentacaoInternaSearchView'),
      'GrupoTipoMovimentacaoInternaSearchView'
    ),
    exact: true,
    path: '/mlf/grupoTipoMovimentacaoInternaSearchView',
  },
  {
    component: createLoadable(() => import('../ger/grupoServico/GrupoServicoEditView'), 'GrupoServicoEditView'),
    path: '/mlf/grupoServicoSearchView/edit',
  },
  {
    component: createLoadable(() => import('../ger/grupoServico/GrupoServicoSearchView'), 'GrupoServicoSearchView'),
    exact: true,
    path: '/mlf/grupoServicoSearchView',
  },
  {
    component: createLoadable(() => import('../ger/grupoForCli/GrupoForCliEditView'), 'GrupoForCliEditView'),
    path: '/mlf/grupoForcliSearchView/edit',
  },
  {
    component: createLoadable(() => import('../ger/grupoForCli/GrupoForCliSearchView'), 'GrupoForCliSearchView'),
    exact: true,
    path: '/mlf/grupoForcliSearchView',
  },
  {
    component: createLoadable(() => import('./unidadeMedida/UnidadeMedidaEditView'), 'UnidadeMedidaEditView'),
    path: '/mlf/unidadeMedidaSearchView/edit',
  },
  {
    component: createLoadable(() => import('./unidadeMedida/UnidadeMedidaSearchView'), 'UnidadeMedidaSearchView'),
    exact: true,
    path: '/mlf/unidadeMedidaSearchView',
  },
  {
    component: createLoadable(() => import('./classificacaoServico/ClassificacaoServicoEditView'), 'ClassificacaoServicoEditView'),
    path: '/mlf/classificacaoServicoSearchView/edit',
  },
  {
    component: createLoadable(() => import('./classificacaoServico/ClassificacaoServicoSearchView'), 'ClassificacaoServicoSearchView'),
    exact: true,
    path: '/mlf/classificacaoServicoSearchView',
  },
  {
    component: createLoadable(() => import('./creditoCiap/CreditoCiapEditView'), 'CreditoCiapEditView'),
    path: '/mlf/creditoCiapSearchView/edit',
  },
  {
    component: createLoadable(() => import('./creditoCiap/CreditoCiapSearchView'), 'CreditoCiapSearchView'),
    exact: true,
    path: '/mlf/creditoCiapSearchView',
  },
  {
    component: createLoadable(() => import('./sociedadeUniProfissional/SociedadeUniProfissionalEditView'), 'SociedadeUniProfissionalEditView'),
    path: '/mlf/sociedadeUniProfissionalSearchView/edit',
  },
  {
    component: createLoadable(() => import('./sociedadeUniProfissional/SociedadeUniProfissionalSearchView'), 'SociedadeUniProfissionalSearchView'),
    exact: true,
    path: '/mlf/sociedadeUniProfissionalSearchView',
  },
  {
    component: createLoadable(() => import('./equipamentoFiscal/EquipamentoFiscalEditView'), 'EquipamentoFiscalEditView'),
    path: '/mlf/equipamentoFiscalSearchView/edit',
  },
  {
    component: createLoadable(() => import('./equipamentoFiscal/EquipamentoFiscalSearchView'), 'EquipamentoFiscalSearchView'),
    exact: true,
    path: '/mlf/equipamentoFiscalSearchView',
  },
  {
    component: createLoadable(() => import('./ajusteInfoFiscal/AjusteInfoFiscalEditView'), 'AjusteInfoFiscalEditView'),
    path: '/mlf/ajusteInfoFiscalSearchView/edit',
  },
  {
    component: createLoadable(() => import('./ajusteInfoFiscal/AjusteInfoFiscalSearchView'), 'AjusteInfoFiscalSearchView'),
    exact: true,
    path: '/mlf/ajusteInfoFiscalSearchView',
  },
  {
    component: createLoadable(() => import('./origemMercadoria/OrigemMercadoriaEditView'), 'OrigemMercadoriaEditView'),
    path: '/mlf/origemMercadoriaSearchView/edit',
  },
  {
    component: createLoadable(() => import('./origemMercadoria/OrigemMercadoriaSearchView'), 'OrigemMercadoriaSearchView'),
    exact: true,
    path: '/mlf/origemMercadoriaSearchView',
  },
  {
    component: createLoadable(() => import('./situacaoTributaria/SituacaoTributariaEditView'), 'SituacaoTributariaEditView'),
    path: '/mlf/situacaoTributariaSearchView/edit',
  },
  {
    component: createLoadable(() => import('./situacaoTributaria/SituacaoTributariaSearchView'), 'SituacaoTributariaSearchView'),
    exact: true,
    path: '/mlf/situacaoTributariaSearchView',
  },
  {
    component: createLoadable(() => import('./cstIpi/CSTIPIEditView'), 'CSTIPIEditView'),
    path: '/mlf/cstIPISearchView/edit',
  },
  {
    component: createLoadable(() => import('./cstIpi/CSTIPISearchView'), 'CSTIPISearchView'),
    exact: true,
    path: '/mlf/cstIPISearchView',
  },
  {
    component: createLoadable(() => import('./atividadeEconomica/AtividadeEconomicaEditView'), 'AtividadeEconomicaEditView'),
    path: '/mlf/atividadeEconomicaSearchView/edit',
  },
  {
    component: createLoadable(() => import('./atividadeEconomica/AtividadeEconomicaSearchView'), 'AtividadeEconomicaSearchView'),
    exact: true,
    path: '/mlf/atividadeEconomicaSearchView',
  },
  {
    component: createLoadable(() => import('./cstCofins/CSTCOFINSEditView'), 'CSTCOFINSEditView'),
    path: '/mlf/cstCOFINSSearchView/edit',
  },
  {
    component: createLoadable(() => import('./cstCofins/CSTCOFINSSearchView'), 'CSTCOFINSSearchView'),
    exact: true,
    path: '/mlf/cstCOFINSSearchView',
  },
  {
    component: createLoadable(() => import('./cstPis/CSTPISEditView'), 'CSTPISEditView'),
    path: '/mlf/cstPISSearchView/edit',
  },
  {
    component: createLoadable(() => import('./cstPis/CSTPISSearchView'), 'CSTPISSearchView'),
    exact: true,
    path: '/mlf/cstPISSearchView',
  },
  {
    component: createLoadable(() => import('./cstSimplesNacional/CSTSimplesNacionalEditView'), 'CSTSimplesNacionalEditView'),
    path: '/mlf/cstSimplesNacionalSearchView/edit',
  },
  {
    component: createLoadable(() => import('./cstSimplesNacional/CSTSimplesNacionalSearchView'), 'CSTSimplesNacionalSearchView'),
    exact: true,
    path: '/mlf/cstSimplesNacionalSearchView',
  },
  {
    component: createLoadable(() => import('./cfop/CFOPEditView'), 'CFOPEditView'),
    path: '/mlf/cfopSearchView/edit',
  },
  {
    component: createLoadable(() => import('./cfop/CFOPSearchView'), 'CFOPSearchView'),
    exact: true,
    path: '/mlf/cfopSearchView',
  },
  {
    component: createLoadable(() => import('./especie/EspecieEditView'), 'EspecieEditView'),
    path: '/mlf/especieSearchView/edit',
  },
  {
    component: createLoadable(() => import('./especie/EspecieSearchView'), 'EspecieSearchView'),
    exact: true,
    path: '/mlf/especieSearchView',
  },
  {
    component: createLoadable(() => import('./serie/SerieEditView'), 'SerieEditView'),
    path: '/mlf/serieSearchView/edit',
  },
  {
    component: createLoadable(() => import('./serie/SerieSearchView'), 'SerieSearchView'),
    exact: true,
    path: '/mlf/serieSearchView',
  },
  {
    component: createLoadable(() => import('./naturezaReceita/NaturezaReceitaEditView'), 'NaturezaReceitaEditView'),
    path: '/mlf/naturezaReceitaSearchView/edit',
  },
  {
    component: createLoadable(() => import('./naturezaReceita/NaturezaReceitaSearchView'), 'NaturezaReceitaSearchView'),
    exact: true,
    path: '/mlf/naturezaReceitaSearchView',
  },
  {
    component: createLoadable(() => import('./tipoCredito/TipoCreditoEditView'), 'TipoCreditoEditView'),
    path: '/mlf/tipoCreditoSearchView/edit',
  },
  {
    component: createLoadable(() => import('./tipoCredito/TipoCreditoSearchView'), 'TipoCreditoSearchView'),
    exact: true,
    path: '/mlf/tipoCreditoSearchView',
  },
  {
    component: createLoadable(() => import('../ger/sociedadeContaParticipacao/SCPEditView'), 'MLFSCPEditView'),
    path: '/mlf/scpSearchView/edit',
  },
  {
    component: createLoadable(() => import('../ger/sociedadeContaParticipacao/SCPSearchView'), 'MLFSCPSearchView'),
    exact: true,
    path: '/mlf/scpSearchView',
  },
  {
    component: createLoadable(() => import('./selic/SelicEditView'), 'SelicEditView'),
    path: '/mlf/selicSearchView/edit',
  },
  {
    component: createLoadable(() => import('./selic/SelicSearchView'), 'SelicSearchView'),
    exact: true,
    path: '/mlf/selicSearchView',
  },
  {
    component: createLoadable(() => import('./naturezaCredito/NaturezaCreditoEditView'), 'NaturezaCreditoEditView'),
    path: '/mlf/naturezaCreditoSearchView/edit',
  },
  {
    component: createLoadable(() => import('./naturezaCredito/NaturezaCreditoSearchView'), 'NaturezaCreditoSearchView'),
    exact: true,
    path: '/mlf/naturezaCreditoSearchView',
  },
  {
    component: createLoadable(() => import('./configCsll/ConfigCsllEditView'), 'ConfigCsllEditView'),
    path: '/mlf/configCsllSearchView/edit',
  },
  {
    component: createLoadable(() => import('./configCsll/ConfigCsllSearchView'), 'ConfigCsllSearchView'),
    exact: true,
    path: '/mlf/configCsllSearchView',
  },
  {
    component: createLoadable(() => import('./configIcms/ConfigIcmsEditView'), 'ConfigIcmsEditView'),
    path: '/mlf/configIcmsSearchView/edit',
  },
  {
    component: createLoadable(() => import('./configIcms/ConfigIcmsSearchView'), 'ConfigIcmsSearchView'),
    exact: true,
    path: '/mlf/configIcmsSearchView',
  },
  {
    component: createLoadable(() => import('./configIcmsST/ConfigIcmsSTEditView'), 'ConfigIcmsSTEditView'),
    path: '/mlf/configIcmsSTSearchView/edit',
  },
  {
    component: createLoadable(() => import('./configIcmsST/ConfigIcmsSTSearchView'), 'ConfigIcmsSTSearchView'),
    exact: true,
    path: '/mlf/configIcmsSTSearchView',
  },
  {
    component: createLoadable(() => import('./configIcmsDa/ConfigIcmsDaEditView'), 'ConfigIcmsDaEditView'),
    path: '/mlf/configIcmsDaSearchView/edit',
  },
  {
    component: createLoadable(() => import('./configIcmsDa/ConfigIcmsDaSearchView'), 'ConfigIcmsDaSearchView'),
    exact: true,
    path: '/mlf/configIcmsDaSearchView',
  },
  {
    component: createLoadable(() => import('./configIcmsDifal/ConfigIcmsDifalEditView'), 'ConfigIcmsDifalEditView'),
    path: '/mlf/configIcmsDifalSearchView/edit',
  },
  {
    component: createLoadable(() => import('./configIcmsDifal/ConfigIcmsDifalSearchView'), 'ConfigIcmsDifalSearchView'),
    exact: true,
    path: '/mlf/configIcmsDifalSearchView',
  },
  {
    component: createLoadable(() => import('./configIpi/ConfigIpiEditView'), 'ConfigIpiEditView'),
    path: '/mlf/configIpiSearchView/edit',
  },
  {
    component: createLoadable(() => import('./configIpi/ConfigIpiSearchView'), 'ConfigIpiSearchView'),
    exact: true,
    path: '/mlf/configIpiSearchView',
  },
  {
    component: createLoadable(() => import('./ncmGenero/NcmGeneroEditView'), 'NcmGeneroEditView'),
    path: '/mlf/ncmGeneroSearchView/edit',
  },
  {
    component: createLoadable(() => import('./ncmGenero/NcmGeneroSearchView'), 'NcmGeneroSearchView'),
    exact: true,
    path: '/mlf/ncmGeneroSearchView',
  },
  {
    component: createLoadable(() => import('./ncm/NcmEditView'), 'NcmEditView'),
    path: '/mlf/ncmSearchView/edit',
  },
  {
    component: createLoadable(() => import('./ncm/NcmSearchView'), 'NcmSearchView'),
    exact: true,
    path: '/mlf/ncmSearchView',
  },
  {
    component: createLoadable(() => import('./tipoProduto/TipoProdutoEditVIew'), 'TipoProdutoEditVIew'),
    path: '/mlf/tipoProdutoSearchView/edit',
  },
  {
    component: createLoadable(() => import('./tipoProduto/TipoProdutoSearchView'), 'TipoProdutoSearchView'),
    exact: true,
    path: '/mlf/tipoProdutoSearchView',
  },
  {
    component: createLoadable(() => import('./configIrpj/ConfigIrpjEditView'), 'ConfigIrpjEditView'),
    path: '/mlf/configIrpjSearchView/edit',
  },
  {
    component: createLoadable(() => import('./configIrpj/ConfigIrpjSearchView'), 'ConfigIrpjSearchView'),
    exact: true,
    path: '/mlf/configIrpjSearchView',
  },
  {
    component: createLoadable(() => import('./configPis/ConfigPisEditView'), 'ConfigPisEditView'),
    path: '/mlf/configPisSearchView/edit',
  },
  {
    component: createLoadable(() => import('./configPis/ConfigPisSearchView'), 'ConfigPisSearchView'),
    exact: true,
    path: '/mlf/configPisSearchView',
  },
  {
    component: createLoadable(() => import('./configIss/ConfigIssEditView'), 'ConfigIssEditView'),
    exact: true,
    path: '/mlf/configIssSearchView/edit',
  },
  {
    component: createLoadable(() => import('./configIss/ConfigIssSearchView'), 'ConfigIssSearchView'),
    exact: true,
    path: '/mlf/configIssSearchView',
  },
  {
    component: createLoadable(() => import('./configRetencaoFonte/ConfigRetencaoFonteEditView'), 'ConfigRetencaoFonteEditView'),
    path: '/mlf/configRetencaoFonteSearchView/edit',
  },
  {
    component: createLoadable(() => import('./configRetencaoFonte/ConfigRetencaoFonteSearchView'), 'ConfigRetencaoFonteSearchView'),
    exact: true,
    path: '/mlf/configRetencaoFonteSearchView',
  },
  {
    component: createLoadable(() => import('./configSimplesNacional/ConfigSimplesNacionalEditView'), 'ConfigSimplesNacionalEditView'),
    path: '/mlf/configSimplesNacionalSearchView/edit',
  },
  {
    component: createLoadable(() => import('./configSimplesNacional/ConfigSimplesNacionalSearchView'), 'ConfigSimplesNacionalSearchView'),
    exact: true,
    path: '/mlf/configSimplesNacionalSearchView',
  },
  {
    component: createLoadable(() => import('./codigoReceitaFinanceira/CodigoReceitaFinanceiraSearchView'), 'CodigoReceitaFinanceiraSearchView'),
    exact: true,
    path: '/mlf/codigoReceitaFinanceiraSearchView',
  },
  {
    component: createLoadable(() => import('./codigoReceitaFinanceira/CodigoReceitaFinanceiraEditView'), 'CodigoReceitaFinanceiraEditView'),
    exact: true,
    path: '/mlf/codigoReceitaFinanceiraSearchView/edit',
  },
  {
    component: createLoadable(() => import('./codigoDctf/CodigoDctfSearchView'), 'CodigoDctfSearchView'),
    exact: true,
    path: '/mlf/codigoDctfSearchView',
  },
  {
    component: createLoadable(() => import('./codigoDctf/CodigoDctfEditView'), 'CodigoDctfEditView'),
    exact: true,
    path: '/mlf/codigoDctfSearchView/edit',
  },
  {
    component: createLoadable(
      () => import('./report/conferenciaNotaEntradaSaida/ConferenciaNotasEntradaReportView'),
      'ConferenciaNotasEntradaReportView'
    ),
    path: '/mlf/conferenciaNotasEntradaReportView',
  },
  {
    component: createLoadable(() => import('./report/retencoesClienteFornecedor/RetencoesFornecedorReportView'), 'RetencoesFornecedorReportView'),
    path: '/mlf/retencoesFornecedorReportView',
  },
  {
    component: createLoadable(
      () => import('./report/demonstracaoCombustivel/DemonstracaoValorCombustivelReportView'),
      'DemonstracaoValorCombustivelReportView'
    ),
    path: '/mlf/demonstracaoValorCombustivelReportView',
  },
  {
    component: createLoadable(() => import('./report/servicoCliente/ServicoClienteReportView'), 'ServicoClienteReportView'),
    path: '/mlf/servicoClienteReportView',
  },
  {
    component: createLoadable(() => import('./report/sequenciaNota/SequenciaNotaReportView'), 'SequenciaNotaReportView'),
    path: '/mlf/sequenciaNotaReportView',
  },
  {
    component: createLoadable(
      () => import('./report/conferenciaNotaEntradaSaida/ConferenciaNotasSaidaReportView'),
      'ConferenciaNotasSaidaReportView'
    ),
    path: '/mlf/conferenciaNotasSaidaReportView',
  },
  {
    component: createLoadable(() => import('./report/retencoesClienteFornecedor/RetencoesClienteReportView'), 'RetencoesClienteReportView'),
    path: '/mlf/retencoesClienteReportView',
  },
  {
    component: createLoadable(() => import('./report/forcliRelacaoCompleta/ForcliRelacaoCompleta'), 'ForcliRelacaoCompleta'),
    path: '/mlf/forcliRelacaoCompleta',
  },
  {
    component: createLoadable(() => import('./report/relacaoProduto/RelacaoProdutoReportView'), 'RelacaoProdutoReportView'),
    path: '/mlf/relacaoProdutoReportView',
  },
  {
    component: createLoadable(
      () => import('./report/demonstrativoCstIcmsIpi/DemonstrativoCstIcmsIpiReportView'),
      'DemonstrativoCstIcmsIpiReportView'
    ),
    path: '/mlf/demonstrativoCstIcmsIpiReportView',
  },
  {
    component: createLoadable(() => import('./report/ciap/CiapReportView'), 'CiapReportView'),
    path: '/mlf/ciapReportView',
  },
  {
    component: createLoadable(() => import('./report/declaracaofaturamento/RelacaoFaturamentoBancoReportView'), 'RelacaoFaturamentoBancoReportView'),
    path: '/mlf/relacaoFaturamentoBancoReportView',
  },
  {
    component: createLoadable(() => import('./report/simplesNacional/SimplesNacionalReportView'), 'SimplesNacionalReportView'),
    path: '/mlf/simplesNacionalReportView',
  },
  {
    component: createLoadable(
      () => import('./report/faturamentoBrutoModelo1/FaturamentoBrutoModelo1ReportView'),
      'FaturamentoBrutoModelo1ReportView'
    ),
    path: '/mlf/faturamentoBrutoModelo1ReportView',
  },
  {
    component: createLoadable(
      () => import('./report/faturamentoBrutoModelo2/FaturamentoBrutoModelo2ReportView'),
      'FaturamentoBrutoModelo2ReportView'
    ),
    path: '/mlf/faturamentoBrutoModelo2ReportView',
  },
  {
    component: createLoadable(
      () => import('./report/faturamentoBrutoModelo3/FaturamentoBrutoModelo3ReportView'),
      'FaturamentoBrutoModelo3ReportView'
    ),
    path: '/mlf/faturamentoBrutoModelo3ReportView',
  },
  {
    component: createLoadable(
      () => import('./report/faturamentoBrutoModelo4/FaturamentoBrutoModelo4ReportView'),
      'FaturamentoBrutoModelo4ReportView'
    ),
    path: '/mlf/faturamentoBrutoModelo4ReportView',
  },
  {
    component: createLoadable(
      () => import('./report/previsaofaturamentobruto/PrevisaoFaturamentoBrutoReportView'),
      'PrevisaoFaturamentoBrutoReportView'
    ),
    path: '/mlf/previsaoFaturamentoBrutoReportView',
  },
  {
    component: createLoadable(() => import('./report/analiseDeSTDA/AnaliseDeSTDAReportView'), 'AnaliseDeSTDAReportView'),
    path: '/mlf/analiseDeSTDAReportView',
  },
  {
    component: createLoadable(
      () => import('./report/resumoCFOPInterestadual/ResumoCFOPInterestadualReportView'),
      'ResumoCFOPInterestadualReportView'
    ),
    path: '/mlf/resumoCFOPInterestadualReportView',
  },
  {
    component: createLoadable(
      () => import('./codigoReceitaDetalhamento/CodigoReceitaFinanceiraDetalhamentoEditView'),
      'CodigoReceitaFinanceiraDetalhamentoEditView'
    ),
    exact: true,
    path: '/mlf/codigoReceitaFinanceiraDetalhamentoSearchView/edit',
  },
  {
    component: createLoadable(
      () => import('./codigoReceitaDetalhamento/CodigoReceitaFinanceiraDetalhamentoSearchView'),
      'CodigoReceitaFinanceiraDetalhamentoSearchView'
    ),
    exact: true,
    path: '/mlf/codigoReceitaFinanceiraDetalhamentoSearchView',
  },
  {
    component: createLoadable(() => import('./multa/MultaEditView'), 'MultaEditView'),
    path: '/mlf/multaSearchView/edit',
  },
  {
    component: createLoadable(() => import('./multa/MultaSearchView'), 'MultaSearchView'),
    exact: true,
    path: '/mlf/multaSearchView',
  },
  {
    component: createLoadable(() => import('./observacaoPadrao/ObservacaoPadraoEditView'), 'ObservacaoPadraoEditView'),
    path: '/mlf/observacaoPadraoSearchView/edit',
  },
  {
    component: createLoadable(() => import('./observacaoPadrao/ObservacaoPadraoSearchView'), 'ObservacaoPadraoSearchView'),
    exact: true,
    path: '/mlf/observacaoPadraoSearchView',
  },
  {
    component: createLoadable(() => import('./configLivroFolha/ConfigLivroFolhaMFLEditView'), 'ConfigLivroFolhaMFLEditView'),
    exact: true,
    path: '/mlf/configLivroFolhaMFLEditView',
  },
  {
    component: createLoadable(() => import('./limiteMEEPPP/ConfigLimiteMEEPPPEditView'), 'ConfigLimiteMEEPPPEditView'),
    exact: true,
    path: '/mlf/configLimiteMEEPPPEditView',
  },
  {
    component: createLoadable(
      () => import('./indiceParticipacaoMunicipio/IndiceParticipacaoMunicipioSearchView'),
      'IndiceParticipacaoMunicipioSearchView'
    ),
    exact: true,
    path: '/mlf/indiceParticipacaoMunicipioSearchView',
  },
  {
    component: createLoadable(
      () => import('./indiceParticipacaoMunicipio/IndiceParticipacaoMunicipioEditView'),
      'IndiceParticipacaoMunicipioEditView'
    ),
    path: '/mlf/indiceParticipacaoMunicipioSearchView/edit',
  },
  {
    component: createLoadable(() => import('./report/reducaoZ/ReducaoZReportView'), 'ReducaoZReportView'),
    path: '/mlf/reducaoZReportView',
  },
  {
    component: createLoadable(
      () => import('./report/relacaoEntradaSaida/codigoFiscal/listagem/RelacaoCodigoFiscalEntradaReportView'),
      'RelacaoCodigoFiscalEntradaReportView'
    ),
    path: '/mlf/relacaoCodigoFiscalEntradaReportView',
  },
  {
    component: createLoadable(
      () => import('./report/relacaoEntradaSaida/codigoFiscal/listagem/RelacaoCodigoFiscalSaidaReportView'),
      'RelacaoCodigoFiscalSaidaReportView'
    ),
    path: '/mlf/relacaoCodigoFiscalSaidaReportView',
  },
  {
    component: createLoadable(() => import('./report/relacaoEntradaSaida/damefVafCompleta/DamefVafCompletaReportView'), 'DamefVafCompletaReportView'),
    path: '/mlf/damefVafCompletaReportView',
  },
  {
    component: createLoadable(() => import('./report/relacaoEntradaSaida/damefVafSimples/DamefVafSimplesReportView'), 'DamefVafSimplesReportView'),
    path: '/mlf/damefVafSimplesReportView',
  },
  {
    component: createLoadable(
      () => import('./report/prestacaoServicoEntradaSaida/PrestacaoServicoSaidaReportView'),
      'PrestacaoServicoSaidaReportView'
    ),
    path: '/mlf/prestacaoServicoSaidaReportView',
  },
  {
    component: createLoadable(() => import('./report/servicoTomado/ServicoTomadoReportView'), 'ServicoTomadoReportView'),
    path: '/mlf/servicoTomadoReportView',
  },
  {
    component: createLoadable(
      () => import('./report/relacaoEntradaSaida/codigoFiscal/servico/RelacaoCodigoFiscalServicoSaidaReportView'),
      'RelacaoCodigoFiscalServicoSaidaReportView'
    ),
    path: '/mlf/relacaoCodigoFiscalServicoSaidaReportView',
  },
  {
    component: createLoadable(
      () => import('./report/faturamentoBrutoServico/FaturamentoBrutoServicoReportView'),
      'FaturamentoBrutoServicoReportView'
    ),
    path: '/mlf/faturamentoBrutoServicoReportView',
  },
  {
    component: createLoadable(
      () => import('./report/prestacaoServicoEntradaSaida/PrestacaoServicoEntradaReportView'),
      'PrestacaoServicoEntradaReportView'
    ),
    path: '/mlf/prestacaoServicoEntradaReportView',
  },
  {
    component: createLoadable(
      () => import('./report/relacaoEntradaSaida/codigoFiscal/servico/RelacaoCodigoFiscalServicoEntradaReportView'),
      'RelacaoCodigoFiscalServicoEntradaReportView'
    ),
    path: '/mlf/relacaoCodigoFiscalServicoEntradaReportView',
  },
  {
    component: createLoadable(() => import('./report/resumoImposto/ResumoImpostoReportView'), 'ResumoImpostoReportView'),
    path: '/mlf/resumoImpostoReportView',
  },
  {
    component: createLoadable(() => import('./report/memorialImposto/MemoriaImpostoReportView'), 'MemoriaImpostoReportView'),
    path: '/mlf/memoriaImpostoReportView',
  },
  {
    component: createLoadable(() => import('./report/demonstrativoImposto/DemonstrativoImpostoReportView'), 'DemonstrativoImpostoReportView'),
    path: '/mlf/demonstrativoImpostoReportView',
  },
  {
    component: createLoadable(() => import('./report/gnre/GnreReportView'), 'GnreReportView'),
    path: '/mlf/gnreReportView',
  },
  {
    component: createLoadable(() => import('./report/dapi/DapiReportView'), 'DapiReportView'),
    path: '/mlf/dapiReportView',
  },
  {
    component: createLoadable(() => import('./tipoMovimentacaoInterna/TipoMovimentacaoInternaEditView'), 'TipoMovimentacaoInternaEditView'),
    path: '/mlf/tipoMovimentacaoInternaSearchView/edit',
  },
  {
    component: createLoadable(() => import('./tipoMovimentacaoInterna/TipoMovimentacaoInternaSearchView'), 'TipoMovimentacaoInternaSearchView'),
    exact: true,
    path: '/mlf/tipoMovimentacaoInternaSearchView',
  },
  {
    component: createLoadable(
      () => import('./report/relacaoEntradaSaida/especieDocumento/RelacaoEntradaEspecieDocumentoReportView'),
      'RelacaoEntradaEspecieDocumentoReportView'
    ),
    exact: true,
    path: '/mlf/relacaoEntradaEspecieDocumentoReportView',
  },
  {
    component: createLoadable(
      () => import('./report/relacaoEntradaSaida/especieDocumento/RelacaoSaidaEspecieDocumentoReportView'),
      'RelacaoSaidaEspecieDocumentoReportView'
    ),
    exact: true,
    path: '/mlf/relacaoSaidaEspecieDocumentoReportView',
  },
  {
    component: createLoadable(
      () => import('./report/relacaoEntradaSaida/forcli/RelacaoEntradaFornecedorReportView'),
      'RelacaoEntradaFornecedorReportView'
    ),
    exact: true,
    path: '/mlf/relacaoEntradaFornecedorReportView',
  },
  {
    component: createLoadable(() => import('./report/relacaoEntradaSaida/forcli/RelacaoSaidaClienteReportView'), 'RelacaoSaidaClienteReportView'),
    exact: true,
    path: '/mlf/relacaoSaidaClienteReportView',
  },
  {
    component: createLoadable(
      () => import('./report/relacaoEntradaSaida/aliquota/RelacaoEntradaAliquotaReportView'),
      'RelacaoEntradaAliquotaReportView'
    ),
    exact: true,
    path: '/mlf/relacaoEntradaAliquotaReportView',
  },
  {
    component: createLoadable(() => import('./report/relacaoEntradaSaida/aliquota/RelacaoSaidaAliquotaReportView'), 'RelacaoSaidaAliquotaReportView'),
    exact: true,
    path: '/mlf/relacaoSaidaAliquotaReportView',
  },
  {
    component: createLoadable(() => import('./report/relacaoEntradaSaida/estado/RelacaoEntradaEstadoReportView'), 'RelacaoEntradaEstadoReportView'),
    exact: true,
    path: '/mlf/relacaoEntradaEstadoReportView',
  },
  {
    component: createLoadable(() => import('./report/relacaoEntradaSaida/estado/RelacaoSaidaEstadoReportView'), 'RelacaoSaidaEstadoReportView'),
    exact: true,
    path: '/mlf/relacaoSaidaEstadoReportView',
  },
  {
    component: createLoadable(() => import('./report/entradaSaidaCidade/EntradaCidadeReportView'), 'EntradaCidadeReportView'),
    exact: true,
    path: '/mlf/entradaCidadeReportView',
  },
  {
    component: createLoadable(() => import('./report/entradaSaidaCidade/SaidaCidadeReportView'), 'SaidaCidadeReportView'),
    exact: true,
    path: '/mlf/saidaCidadeReportView',
  },
  {
    component: createLoadable(
      () => import('./report/relacaoEntradaSaida/tipoPagamento/RelacaoEntradaTipoPagamentoReportView'),
      'RelacaoEntradaTipoPagamentoReportView'
    ),
    exact: true,
    path: '/mlf/relacaoEntradaTipoPagamentoReportView',
  },
  {
    component: createLoadable(
      () => import('./report/relacaoEntradaSaida/demonstrativoEntrada/DemonstrativoEntradaICMSDAReportView'),
      'DemonstrativoEntradaICMSDAReportView'
    ),
    exact: true,
    path: '/mlf/demonstrativoEntradaICMSDAReportView',
  },
  {
    component: createLoadable(
      () => import('./report/relacaoEntradaSaida/resumoOperacoesInterestaduais/ResumoOperacoesInterestaduaisEntradaReportView'),
      'ResumoOperacoesInterestaduaisEntradaReportView'
    ),
    exact: true,
    path: '/mlf/resumoOperacoesInterestaduaisEntradaReportView',
  },
  {
    component: createLoadable(
      () => import('./report/relacaoEntradaSaida/resumoOperacoesInterestaduais/ResumoOperacoesInterestaduaisSaidaReportView'),
      'ResumoOperacoesInterestaduaisSaidaReportView'
    ),
    exact: true,
    path: '/mlf/ResumoOperacoesInterestaduaisSaidaReportView',
  },
  {
    component: createLoadable(
      () => import('./report/resumoLancamentoEntradaSaida/ResumoLancamentoEntradaReportView'),
      'ResumoLancamentoEntradaReportView'
    ),
    exact: true,
    path: '/mlf/resumoLancamentoEntradaReportView',
  },
  {
    component: createLoadable(
      () => import('./report/resumoLancamentoEntradaSaida/ResumoLancamentoSaidaReportView'),
      'ResumoLancamentoSaidaReportView'
    ),
    exact: true,
    path: '/mlf/resumoLancamentoSaidaReportView',
  },
  {
    component: createLoadable(
      () => import('./report/relacaoEntradaSaida/aliquotaZeroPisCofins/AliquotaZeroPISCOFINSEntradaReportView'),
      'AliquotaZeroPISCOFINSEntradaReportView'
    ),
    exact: true,
    path: '/mlf/aliquotaZeroPISCOFINSEntradaReportView',
  },
  {
    component: createLoadable(
      () => import('./report/relacaoEntradaSaida/aliquotaZeroPisCofins/AliquotaZeroPISCOFINSSaidaReportView'),
      'AliquotaZeroPISCOFINSSaidaReportView'
    ),
    exact: true,
    path: '/mlf/aliquotaZeroPISCOFINSSaidaReportView',
  },
  {
    component: createLoadable(() => import('./report/resumoDiarioEntradaSaida/ResumoDiarioEntradaReportView'), 'ResumoDiarioEntradaReportView'),
    exact: true,
    path: '/mlf/resumoDiarioEntradaReportView',
  },
  {
    component: createLoadable(() => import('./report/resumoDiarioEntradaSaida/ResumoDiarioSaidaReportView'), 'ResumoDiarioSaidaReportView'),
    exact: true,
    path: '/mlf/resumoDiarioSaidaReportView',
  },
  {
    component: createLoadable(() => import('./report/saidaProduto/SaidaProdutoReportView'), 'SaidaProdutoReportView'),
    exact: true,
    path: '/mlf/saidaProdutoReportView',
  },
  {
    component: createLoadable(
      () => import('./report/relacaoEntradaSaida/relacaoOperacoesEstaduais/RelacaoOperacoesEstaduaisSaidaReportView'),
      'RelacaoOperacoesEstaduaisSaidaReportView'
    ),
    exact: true,
    path: '/mlf/relacaoOperacoesEstaduaisSaidaReportView',
  },
  {
    component: createLoadable(
      () => import('./report/relacaoEntradaSaida/relacaoOperacoesInterestaduais/RelacaoOperacoesInterestaduaisSaidaReportView'),
      'RelacaoOperacoesInterestaduaisSaidaReportView'
    ),
    exact: true,
    path: '/mlf/relacaoOperacoesInterestaduaisSaidaReportView',
  },
  {
    component: createLoadable(
      () => import('./report/relacaoEntradaSaida/retencoesNotasFiscais/RetencoesNotasFiscaisReportView'),
      'RetencoesNotasFiscaisReportView'
    ),
    exact: true,
    path: '/mlf/retencoesNotasFiscaisReportView',
  },
  {
    component: createLoadable(
      () => import('./report/relacaoEntradaSaida/forcliEmitente/ListagemForcliEmitenteReportView'),
      'ListagemForcliEmitenteReportView'
    ),
    exact: true,
    path: '/mlf/listagemForcliEmitenteReportView',
  },
  {
    component: createLoadable(() => import('./report/relacaoEntradaSaida/listagemCliente/ListagemClienteReportView'), 'ListagemClienteReportView'),
    exact: true,
    path: '/mlf/listagemClienteReportView',
  },
  {
    component: createLoadable(() => import('./report/resumoVendas/ResumoVendasReportView'), 'ResumoVendasReportView'),
    exact: true,
    path: '/mlf/resumoVendasReportView',
  },
  {
    component: createLoadable(() => import('./report/dmed/DMEDReportView'), 'DMEDReportView'),
    exact: true,
    path: '/mlf/dmedReportView',
  },
  {
    component: createLoadable(
      () => import('./refazerLancamentosContabeis/RefazerLancamentosContabeisProcessView'),
      'RefazerLancamentosContabeisProcessView'
    ),
    path: '/mlf/refazerLancamentosContabeisProcessView',
  },
  {
    component: createLoadable(() => import('./refazerLancamentosReinf/RefazerLancamentosReinfProcessView'), 'RefazerLancamentosReinfProcessView'),
    path: '/mlf/refazerLancamentosReinfProcessView',
  },
  {
    component: createLoadable(
      () => import('./importarIntegracaoFiscal/ImportarIntegracaoFiscalContabilProcessView'),
      'ImportarIntegracaoFiscalContabilProcessView'
    ),
    path: '/mlf/importarIntegracaoFiscalContabilProcessView',
  },
  {
    component: createLoadable(() => import('./lancamentoFaturamento/LancamentoFaturamentoEditView'), 'LancamentoFaturamentoEditView'),
    path: '/mlf/lancamentoFaturamentoSearchView/edit',
  },
  {
    component: createLoadable(() => import('./lancamentoFaturamento/LancamentoFaturamentoSearchView'), 'LancamentoFaturamentoSearchView'),
    exact: true,
    path: '/mlf/lancamentoFaturamentoSearchView',
  },
  {
    component: createLoadable(() => import('./valorContabil/ValorContabilMLFEditView'), 'ValorContabilMLFEditView'),
    path: '/mlf/valorContabilMLFSearchView/edit',
  },
  {
    component: createLoadable(() => import('./valorContabil/ValorContabilMLFSearchView'), 'ValorContabilMLFSearchView'),
    exact: true,
    path: '/mlf/valorContabilMLFSearchView',
  },
  {
    component: createLoadable(() => import('./giaSpSubItem/GiaSpSubItemEditView'), 'GiaSpSubItemEditView'),
    path: '/mlf/giaSpSubItemSearchView/edit',
  },
  {
    component: createLoadable(() => import('./giaSpSubItem/GiaSpSubItemSearchView'), 'GiaSpSubItemSearchView'),
    exact: true,
    path: '/mlf/giaSpSubItemSearchView',
  },
  {
    component: createLoadable(() => import('./giaSpInfComp/GiaSpInfCompEditView'), 'GiaSpInfCompEditView'),
    path: '/mlf/giaSpInfCompSearchView/edit',
  },
  {
    component: createLoadable(() => import('./giaSpInfComp/GiaSpInfCompSearchView'), 'GiaSpInfCompSearchView'),
    exact: true,
    path: '/mlf/giaSpInfCompSearchView',
  },
  {
    component: createLoadable(() => import('./giaSpLocalidade/GiaSpLocalidadeEditView'), 'GiaSpLocalidadeEditView'),
    path: '/mlf/giaSpLocalidadeSearchView/edit',
  },
  {
    component: createLoadable(() => import('./giaSpLocalidade/GiaSpLocalidadeSearchView'), 'GiaSpLocalidadeSearchView'),
    exact: true,
    path: '/mlf/giaSpLocalidadeSearchView',
  },
  {
    component: createLoadable(() => import('./dipamGiaSp/DipamGiaSpEditView'), 'DipamGiaSpEditView'),
    path: '/mlf/dipamGiaSpSearchView/edit',
  },
  {
    component: createLoadable(() => import('./dipamGiaSp/DipamGiaSpSearchView'), 'DipamGiaSpSearchView'),
    exact: true,
    path: '/mlf/dipamGiaSpSearchView',
  },
  {
    component: createLoadable(() => import('../ger/giaRj/finalidade/FinalidadeEditView'), 'FinalidadeEditView'),
    path: '/mlf/finalidadeSearchView/edit',
  },
  {
    component: createLoadable(() => import('../ger/giaRj/finalidade/FinalidadeSearchView'), 'FinalidadeSearchView'),
    exact: true,
    path: '/mlf/finalidadeSearchView',
  },
  {
    component: createLoadable(() => import('../ger/giaRj/ocorrencia/OcorrenciaEditView'), 'OcorrenciaEditView'),
    path: '/mlf/ocorrenciaSearchView/edit',
  },
  {
    component: createLoadable(() => import('../ger/giaRj/ocorrencia/OcorrenciaSearchView'), 'OcorrenciaSearchView'),
    exact: true,
    path: '/mlf/ocorrenciaSearchView',
  },
  {
    component: createLoadable(() => import('./reinfCFOP/ReinfCFOPEditView'), 'ReinfCFOPEditView'),
    path: '/mlf/reinfCFOPSearchView/edit',
  },
  {
    component: createLoadable(() => import('./reinfCFOP/ReinfCFOPSearchView'), 'ReinfCFOPSearchView'),
    exact: true,
    path: '/mlf/reinfCFOPSearchView',
  },
  {
    component: createLoadable(
      () => import('./report/demonstrativoCSTPISCOFINS/DemonstrativoCSTPISCOFINSReportView'),
      'DemonstrativoCSTPISCOFINSReportView'
    ),
    exact: true,
    path: '/mlf/demonstrativoCSTPISCOFINSReportView',
  },
  {
    component: createLoadable(() => import('./report/impostoPagoPagar/ImpostoPagoPagarReportView'), 'ImpostoPagoPagarReportView'),
    path: '/mlf/impostoPagoPagarReportView',
  },
  {
    component: createLoadable(() => import('./report/tabelaMercadoriaP11/TabelaMercadoriaP11ReportView'), 'TabelaMercadoriaP11ReportView'),
    path: '/mlf/tabelaMercadoriaP11ReportView',
  },
  {
    component: createLoadable(() => import('../ger/giaRj/gia/GiaEditView'), 'GiaEditView'),
    path: '/mlf/giaSearchView/edit',
  },
  {
    component: createLoadable(() => import('../ger/giaRj/gia/GiaSearchView'), 'GiaSearchView'),
    exact: true,
    path: '/mlf/giaSearchView',
  },
  {
    component: createLoadable(() => import('./configImpostoEmpresa/ConfigImpostoEmpresaSearchView'), 'ConfigImpostoEmpresaSearchView'),
    exact: true,
    path: '/mlf/configImpostoEmpresaSearchView',
  },
  {
    component: createLoadable(() => import('./configImpostoEmpresa/ConfigImpostoEmpresaEditView'), 'ConfigImpostoEmpresaEditView'),
    exact: true,
    path: '/mlf/configImpostoEmpresaSearchView/edit',
  },
  {
    component: createLoadable(() => import('./configCofins/ConfigCofinsSearchView'), 'ConfigCofinsSearchView'),
    exact: true,
    path: '/mlf/configCofinsSearchView',
  },
  {
    component: createLoadable(() => import('./configCofins/ConfigCofinsEditView'), 'ConfigCofinsEditView'),
    exact: true,
    path: '/mlf/configCofinsSearchView/edit',
  },
  {
    component: createLoadable(() => import('./configIRRF/ConfigIRRFSearchView'), 'ConfigIRRFSearchView'),
    exact: true,
    path: '/mlf/configIRRFSearchView',
  },
  {
    component: createLoadable(() => import('./configIRRF/ConfigIRRFEditView'), 'ConfigIRRFEditView'),
    exact: true,
    path: '/mlf/configIRRFSearchView/edit',
  },
  {
    component: createLoadable(() => import('./report/darfQuota/DarfQuotaReportView'), 'DarfQuotaReportView'),
    exact: true,
    path: '/mlf/darfQuotaReportView',
  },
  {
    component: createLoadable(() => import('../ger/forcli/ForcliSearchView'), 'ForcliSearchView'),
    exact: true,
    path: '/mlf/forcliSearchView',
  },
  {
    component: createLoadable(() => import('../ger/forcli/ForcliEditView'), 'ForcliEditView'),
    exact: true,
    path: '/mlf/forcliSearchView/edit',
  },
  {
    component: createLoadable(() => import('./icmsInfComp/ICMSInfCompSearchView'), 'ICMSInfCompSearchView'),
    exact: true,
    path: '/mlf/icmsInfCompSearchView',
  },
  {
    component: createLoadable(() => import('./icmsInfComp/ICMSInfCompEditView'), 'ICMSInfCompEditView'),
    exact: true,
    path: '/mlf/icmsInfCompSearchView/edit',
  },
  {
    component: createLoadable(() => import('./guiaAvulsa/GuiaAvulsaSearchView'), 'GuiaAvulsaSearchView'),
    exact: true,
    path: '/mlf/guiaAvulsaSearchView',
  },
  {
    component: createLoadable(() => import('./guiaAvulsa/GuiaAvulsaEditView'), 'GuiaAvulsaEditView'),
    exact: true,
    path: '/mlf/guiaAvulsaSearchView/edit',
  },
  {
    component: createLoadable(() => import('./report/conhecimentoTransporte/ConhecimentoTransporteReportView'), 'ConhecimentoTransporteReportView'),
    exact: true,
    path: '/mlf/conhecimentoTransporteReportView',
  },
  {
    component: createLoadable(() => import('./dacAlExportacao/DacAlExportacaoProcessView'), 'DacAlExportacaoProcessView'),
    exact: true,
    path: '/mlf/dacAlExportacaoProcessView',
  },
  {
    component: createLoadable(() => import('./dmaBAExportacao/DmaBAExportacaoProcessView'), 'DmaBAExportacaoProcessView'),
    exact: true,
    path: '/mlf/dmaBAExportacaoProcessView',
  },
  {
    component: createLoadable(() => import('./diefCe/DiefCeEditView'), 'DiefCeEditView'),
    exact: true,
    path: '/mlf/diefCeEditView',
  },
  {
    component: createLoadable(() => import('./diefCe/DiefCeConfiguracaoView'), 'DiefCeConfiguracaoView'),
    exact: true,
    path: '/mlf/diefCeConfiguracaoView',
  },
  {
    component: createLoadable(() => import('./report/guiaDarIss/GuiaDarIssReportView'), 'GuiaDarIssReportView'),
    exact: true,
    path: '/mlf/guiaDarIssReportView',
  },
  {
    component: createLoadable(() => import('./empresaCNO/EmpresaCNOSearchView'), 'EmpresaCNOSearchView'),
    exact: true,
    path: '/mlf/empresaCNOSearchView',
  },
  {
    component: createLoadable(() => import('./empresaCNO/EmpresaCNOEditView'), 'EmpresaCNOEditView'),
    exact: true,
    path: '/mlf/empresaCNOSearchView/edit',
  },
  {
    component: createLoadable(
      () => import('./report/controleProducaoEstoque/ControleProducaoEstoqueReportView'),
      'ControleProducaoEstoqueReportView'
    ),
    path: '/mlf/controleProducaoEstoqueReportView',
  },
  {
    component: createLoadable(() => import('./report/dipj/DIPJReportView'), 'DIPJReportView'),
    exact: true,
    path: '/mlf/dipjReportView',
  },
  {
    component: createLoadable(() => import('./report/baixasAtivo/BaixasAtivoReportView'), 'BaixasAtivoReportView'),
    exact: true,
    path: '/mlf/baixasAtivoReportView',
  },
  {
    component: createLoadable(() => import('./exportacaoDeSTDA/ExportacaoDeSTDAProcessView'), 'ExportacaoDeSTDAProcessView'),
    exact: true,
    path: '/mlf/exportacaoDeSTDAProcessView',
  },
  {
    component: createLoadable(() => import('./exportacaoDNF/ExportacaoDNFProcessView'), 'ExportacaoDNFProcessView'),
    exact: true,
    path: '/mlf/exportacaoDNFProcessView',
  },
  {
    component: createLoadable(() => import('./exportacaoADE/ExportacaoADEProcessView'), 'ExportacaoADEProcessView'),
    exact: true,
    path: '/mlf/exportacaoADEProcessView',
  },
  {
    component: createLoadable(() => import('./exportacaoGIA/ExportacaoGIARJProcessView'), 'ExportacaoGIARJProcessView'),
    exact: true,
    path: '/mlf/exportacaoGIARJProcessView',
  },
  {
    component: createLoadable(() => import('./declanExportacao/DeclanExportacaoProcessView'), 'DeclanExportacaoProcessView'),
    exact: true,
    path: '/mlf/declanExportacaoProcessView',
  },
  {
    component: createLoadable(() => import('./exportacaoGIASP/ExportacaoGIASPProcessView'), 'ExportacaoGIASPProcessView'),
    exact: true,
    path: '/mlf/exportacaoGIASPProcessView',
  },
  {
    component: createLoadable(() => import('./exportacaoGIAMT/ExportacaoGIAMTProcessView'), 'ExportacaoGIAMTProcessView'),
    exact: true,
    path: '/mlf/exportacaoGIAMTProcessView',
  },
  {
    component: createLoadable(() => import('./exportacaoGIAMT/ConfigExportacaoGIAMTEditView'), 'ConfigExportacaoGIAMTEditView'),
    exact: true,
    path: '/mlf/configExportacaoGIAMTEditView',
  },
  {
    component: createLoadable(() => import('./diefMaExportacao/DiefMaExportacaoProcessView'), 'DiefMaExportacaoProcessView'),
    exact: true,
    path: '/mlf/diefMaExportacaoProcessView',
  },
  {
    component: createLoadable(() => import('./exportacaoSintegra/ExportacaoSintegraMLFProcessView'), 'ExportacaoSintegraMLFProcessView'),
    exact: true,
    path: '/mlf/exportacaoSintegraMLFProcessView',
  },
  {
    component: createLoadable(() => import('./inventario/InventarioSearchView'), 'InventarioSearchView'),
    exact: true,
    path: '/mlf/inventarioSearchView',
  },
  {
    component: createLoadable(() => import('./inventario/InventarioEditView'), 'InventarioEditView'),
    path: '/mlf/inventarioSearchView/edit',
  },
  {
    component: createLoadable(() => import('./inventario/InventarioEditView'), 'InventarioEditView'),
    path: '/mlf/inventarioSearchView/editProdutosDeducoes',
  },
  {
    component: createLoadable(() => import('./inventario/InventarioProdutoEditView'), 'InventarioProdutoEditView'),
    path: '/mlf/inventarioSearchView/edit',
  },
  {
    component: createLoadable(() => import('./inventario/InventarioDeducaoEditView'), 'InventarioDeducaoEditView'),
    path: '/mlf/inventarioSearchView/edit',
  },
  {
    component: createLoadable(() => import('./produto/ProdutoSearchView'), 'ProdutoSearchView'),
    exact: true,
    path: '/mlf/produtoSearchView',
  },
  {
    component: createLoadable(() => import('./produto/ProdutoEditView'), 'ProdutoEditView'),
    path: '/mlf/produtoSearchView/edit',
  },
  {
    component: createLoadable(() => import('./exportacaoSintegra/ExportacaoSintegraMLFProcessView'), 'ExportacaoSintegraMLFProcessView'),
    exact: true,
    path: '/mlf/exportacaoSintegraMAMLFProcessView',
  },
  {
    component: createLoadable(() => import('./desPBH/DesPBHEditView'), 'DesPBHEditView'),
    exact: true,
    path: '/mlf/desPBHEditView',
  },
  {
    component: createLoadable(() => import('./dapi/ExportacaoDAPIMLFProcessView'), 'ExportacaoDAPIMLFProcessView'),
    exact: true,
    path: '/mlf/exportacaoDAPIMLFProcessView',
  },
  {
    component: createLoadable(() => import('./diefPaExportacao/DiefPaExportacaoProcessView'), 'DiefPaExportacaoProcessView'),
    exact: true,
    path: '/mlf/diefPaExportacaoProcessView',
  },
  {
    component: createLoadable(() => import('./adrcstpr/AdrcstPrExportacaoProcessView'), 'AdrcstPrExportacaoProcessView'),
    exact: true,
    path: '/mlf/adrcstPrExportacaoProcessView',
  },
  {
    component: createLoadable(() => import('./sef2012PEExportacaoProcessView/SEF2012PEExportacaoProcessView'), 'SEF2012PEExportacaoProcessView'),
    exact: true,
    path: '/mlf/sef2012PEExportacaoProcessView',
  },
  {
    component: createLoadable(() => import('./exportacaoGIMPB/ExportacaoGIMPBMLFProcessView'), 'ExportacaoGIMPBMLFProcessView'),
    exact: true,
    path: '/mlf/exportacaoGIMPBMLFProcessView',
  },
  {
    component: createLoadable(() => import('./livroEletronicoFiscalGDF/LivroEletronicoFiscalGDFProcessView'), 'LivroEletronicoFiscalGDFProcessView'),
    exact: true,
    path: '/mlf/livroEletronicoFiscalGDFProcessView',
  },
  {
    component: createLoadable(() => import('./configCFOPCFPSGDF/ConfigGDFCFOPCFPSSearchView'), 'ConfigGDFCFOPCFPSSearchView'),
    exact: true,
    path: '/mlf/configGDFCFOPCFPSSearchView',
  },
  {
    component: createLoadable(() => import('./configCFOPCFPSGDF/ConfigGDFCFOPCFPSEditView'), 'ConfigGDFCFOPCFPSEditView'),
    path: '/mlf/configGDFCFOPCFPSSearchView/edit',
  },
  {
    component: createLoadable(() => import('./obrigacaoIssRecolher/ObrigacaoIssRecolherSearchView'), 'ObrigacaoIssRecolherSearchView'),
    exact: true,
    path: '/mlf/obrigacaoIssRecolherSearchView',
  },
  {
    component: createLoadable(() => import('./obrigacaoIssRecolher/ObrigacaoIssRecolherEditView'), 'ObrigacaoIssRecolherEditView'),
    path: '/mlf/obrigacaoIssRecolherSearchView/edit',
  },
  {
    component: createLoadable(() => import('./obrigacaoIssRecolher/ObrigacaoIssRecolherSearchView'), 'ObrigacaoIssRecolherSearchView'),
    exact: true,
    path: '/mlf/livroEletronicoFiscalGDFProcessView/obrigacaoIssRecolherSearchView',
  },
  {
    component: createLoadable(() => import('./obrigacaoIssRecolher/ObrigacaoIssRecolherEditView'), 'ObrigacaoIssRecolherEditView'),
    path: '/mlf/livroEletronicoFiscalGDFProcessView/obrigacaoIssRecolherSearchView/edit',
  },
  {
    component: createLoadable(() => import('./exportacaoGIARS/ExportacaoGIARSProcessView'), 'ExportacaoGIARSProcessView'),
    exact: true,
    path: '/mlf/exportacaoGIARSProcessView',
  },
  {
    component: createLoadable(
      () => import('./recolhimentoImpostoGraf/GraficoRecolhimentoImpostoProcessView'),
      'GraficoRecolhimentoImpostoProcessView'
    ),
    exact: true,
    path: '/mlf/graficoRecolhimentoImpostoProcessView',
  },
  {
    component: createLoadable(() => import('./faturamentoBrutoGraf/GraficoFaturamentoBrutoProcessView'), 'GraficoFaturamentoBrutoProcessView'),
    exact: true,
    path: '/mlf/graficoFaturamentoBrutoProcessView',
  },
  {
    component: createLoadable(
      () => import('./previsaoFaturamentoBrutoAnoGraf/GraficoPrevisaoFaturamentoBrutoAnoProcessView'),
      'GraficoPrevisaoFaturamentoBrutoAnoProcessView'
    ),
    exact: true,
    path: '/mlf/graficoPrevisaoFaturamentoBrutoAnoProcessView',
  },
  {
    component: createLoadable(
      () => import('./previsaoFaturamentoBrutoAnoAnteriorGraf/GraficoPrevisaoFaturamentoBrutoAnoAnteriorProcessView'),
      'GraficoPrevisaoFaturamentoBrutoAnoAnteriorProcessView'
    ),
    exact: true,
    path: '/mlf/graficoPrevisaoFaturamentoBrutoAnoAnteriorProcessView',
  },
  {
    component: createLoadable(() => import('./report/inventarioP7/InventarioP7ReportView'), 'InventarioP7ReportView'),
    exact: true,
    path: '/mlf/inventarioP7ReportView',
  },
  {
    component: createLoadable(
      () => import('./termoAberturaEncerramentoMLF/TermoAberturaEncerramentoMLFSearchView'),
      'TermoAberturaEncerramentoMLFSearchView'
    ),
    exact: true,
    path: '/mlf/termoAberturaEncerramentoMLFSearchView',
  },
  {
    component: createLoadable(
      () => import('./termoAberturaEncerramentoMLF/TermoAberturaEncerramentoMLFEditView'),
      'TermoAberturaEncerramentoMLFEditView'
    ),
    path: '/mlf/termoAberturaEncerramentoMLFSearchView/edit',
  },
  {
    component: createLoadable(
      () => import('./report/termoAberturaEncerramentoMLF/TermoAberturaEncerramentoMLFReportView'),
      'TermoAberturaEncerramentoMLFReportView'
    ),
    exact: true,
    path: '/mlf/termoAberturaEncerramentoMLFReportView',
  },
  {
    component: createLoadable(() => import('./exportacaoDIMESC/ExportacaoDIMESCProcessView'), 'ExportacaoDIMESCProcessView'),
    exact: true,
    path: '/mlf/exportacaoDIMESCProcessView',
  },
  {
    component: createLoadable(() => import('./exportacaoGIAMTO/ExportacaoGIAMTOProcessView'), 'ExportacaoGIAMTOProcessView'),
    exact: true,
    path: '/mlf/exportacaoGIAMTOProcessView',
  },
  {
    component: createLoadable(() => import('./exportacaoCat792003/ExportacaoCat792003ProcessView'), 'ExportacaoCat792003ProcessView'),
    exact: true,
    path: '/mlf/exportacaoCat792003ProcessView',
  },
  {
    component: createLoadable(() => import('./exportacaoREDF/ExportacaoREDFProcessView'), 'ExportacaoREDFProcessView'),
    exact: true,
    path: '/mlf/exportacaoREDFProcessView',
  },
  {
    component: createLoadable(() => import('./report/inventario/InventarioReportView'), 'InventarioReportView'),
    exact: true,
    path: '/mlf/inventarioReportView',
  },
  {
    component: createLoadable(
      () => import('./report/relacaoEntradaSaida/totalMaquinaRegistradora/TotalMaquinaRegistradoraReportView'),
      'TotalMaquinaRegistradoraReportView'
    ),
    exact: true,
    path: '/mlf/totalMaquinaRegistradoraReportView',
  },
  {
    component: createLoadable(() => import('./configEmpresaMLF/ConfigEmpresaMLFEditView'), 'ConfigEmpresaMLFEditView'),
    exact: true,
    path: '/mlf/configEmpresaMLFEditView',
  },
  {
    component: createLoadable(() => import('./fechamentoRestauracaoMLF/FechamentoRestauracaoMLFProcessView'), 'FechamentoRestauracaoMLFProcessView'),
    exact: true,
    path: '/mlf/fechamentoRestauracaoMLFProcessView',
  },
  {
    component: createLoadable(() => import('./reducaoZ/ReducaoZSearchView'), 'ReducaoZSearchView'),
    exact: true,
    path: '/mlf/reducaoZSearchView',
  },
  {
    component: createLoadable(() => import('./reducaoZ/ReducaoZEditView'), 'ReducaoZEditView'),
    exact: true,
    path: '/mlf/reducaoZSearchView/edit',
  },
  {
    component: createLoadable(() => import('./exportacaoNFVC/ExportacaoNFVCProcessView'), 'ExportacaoNFVCProcessView'),
    exact: true,
    path: '/mlf/exportacaoNFVCProcessView',
  },
  {
    component: createLoadable(() => import('./exportacaoDMED/DMEDExportacaoProcessView'), 'DMEDExportacaoProcessView'),
    exact: true,
    path: '/mlf/dmedExportacaoProcessView',
  },
  {
    component: createLoadable(() => import('./exportacaoSpedEFDIcmsIpi/SpedEFDIcmsIpiExportacaoProcessView'), 'SpedEFDIcmsIpiExportacaoProcessView'),
    exact: true,
    path: '/mlf/spedEFDIcmsIpiExportacaoProcessView',
  },
  {
    component: createLoadable(() => import('./report/resumoEntradaSaida/ResumoEntradaSaidaReportView'), 'ResumoEntradaSaidaReportView'),
    exact: true,
    path: '/mlf/resumoEntradaSaidaReportView',
  },
  {
    component: createLoadable(() => import('./debitoCreditoCOFINS/DebitoCreditoCOFINSSearchView'), 'DebitoCreditoCOFINSSearchView'),
    exact: true,
    path: '/mlf/debitoCreditoCOFINSSearchView',
  },
  {
    component: createLoadable(() => import('./debitoCreditoCOFINS/DebitoCreditoCOFINSEditView'), 'DebitoCreditoCOFINSEditView'),
    exact: true,
    path: '/mlf/debitoCreditoCOFINSSearchView/edit',
  },
  {
    component: createLoadable(() => import('./debitoCreditoIPI/DebitoCreditoIPISearchView'), 'DebitoCreditoIPISearchView'),
    exact: true,
    path: '/mlf/debitoCreditoIPISearchView',
  },
  {
    component: createLoadable(() => import('./debitoCreditoIPI/DebitoCreditoIPIEditView'), 'DebitoCreditoIPIEditView'),
    exact: true,
    path: '/mlf/debitoCreditoIPISearchView/edit',
  },
  {
    component: createLoadable(() => import('./documentosFiscais/DocumentosFiscaisUtilizadosSearchView'), 'DocumentosFiscaisUtilizadosSearchView'),
    exact: true,
    path: '/mlf/documentosFiscaisUtilizadosSearchView',
  },
  {
    component: createLoadable(() => import('./documentosFiscais/DocumentosFiscaisUtilizadosEditView'), 'DocumentosFiscaisUtilizadosEditView'),
    exact: true,
    path: '/mlf/documentosFiscaisUtilizadosSearchView/edit',
  },
  {
    component: createLoadable(() => import('./movimentacaoInterna/MovimentacaoInternaSearchView'), 'MovimentacaoInternaSearchView'),
    exact: true,
    path: '/mlf/movimentacaoInternaSearchView',
  },
  {
    component: createLoadable(() => import('./movimentacaoInterna/MovimentacaoInternaEditView'), 'MovimentacaoInternaEditView'),
    path: '/mlf/movimentacaoInternaSearchView/edit',
  },
  {
    component: createLoadable(() => import('./aliquotaUF/AliquotaUFSearchView'), 'AliquotaUFSearchView'),
    exact: true,
    path: '/mlf/aliquotaUFSearchView',
  },
  {
    component: createLoadable(() => import('./aliquotaUF/AliquotaUFEditView'), 'AliquotaUFEditView'),
    path: '/mlf/aliquotaUFSearchView/edit',
  },
  {
    component: createLoadable(() => import('./ressarcimentoICMSST/RessarcimentoICMSSTSearchView'), 'RessarcimentoICMSSTSearchView'),
    exact: true,
    path: '/mlf/ressarcimentoICMSSTSearchView',
  },
  {
    component: createLoadable(() => import('./ressarcimentoICMSST/RessarcimentoICMSSTEditView'), 'RessarcimentoICMSSTEditView'),
    path: '/mlf/ressarcimentoICMSSTSearchView/edit',
  },
  {
    component: createLoadable(() => import('./obrigacoesICMSRecolher/ObrigacoesICMSRecolherSearchView'), 'ObrigacoesICMSRecolherSearchView'),
    exact: true,
    path: '/mlf/obrigacoesICMSRecolherSearchView',
  },
  {
    component: createLoadable(() => import('./obrigacoesICMSRecolher/ObrigacoesICMSRecolherEditView'), 'ObrigacoesICMSRecolherEditView'),
    exact: true,
    path: '/mlf/obrigacoesICMSRecolherSearchView/edit',
  },
  {
    component: createLoadable(() => import('./exportacaoDIPJ/DIPJExportacaoProcessView'), 'DIPJExportacaoProcessView'),
    exact: true,
    path: '/mlf/dipjExportacaoProcessView',
  },
  {
    component: createLoadable(() => import('./exportacaoDCTF/DCTFExportacaoProcessView'), 'DCTFExportacaoProcessView'),
    exact: true,
    path: '/mlf/dctfExportacaoProcessView',
  },
  {
    component: createLoadable(() => import('./exportacaoSpedPisCofins/SpedPisCofinsExportacaoProcessView'), 'SpedPisCofinsExportacaoProcessView'),
    exact: true,
    path: '/mlf/spedPisCofinsExportacaoProcessView',
  },
  {
    component: createLoadable(() => import('./exportacaoDIF/ExportacaoDIFMLFProcessView'), 'ExportacaoDIFMLFProcessView'),
    exact: true,
    path: '/mlf/exportacaoDIFMLFProcessView',
  },
  {
    component: createLoadable(() => import('./exportacaoGissOnline/ExportacaoGISSMLFProcessView'), 'ExportacaoGISSMLFProcessView'),
    exact: true,
    path: '/mlf/exportacaoGISSMLFProcessView',
  },
  {
    component: createLoadable(() => import('./debitoCreditoIRPJ/DebitoCreditoIRPJSearchView'), 'DebitoCreditoIRPJSearchView'),
    exact: true,
    path: '/mlf/debitoCreditoIRPJSearchView',
  },
  {
    component: createLoadable(() => import('./debitoCreditoIRPJ/DebitoCreditoIRPJEditView'), 'DebitoCreditoIRPJEditView'),
    exact: true,
    path: '/mlf/debitoCreditoIRPJSearchView/edit',
  },
  {
    component: createLoadable(() => import('./debitoCreditoPIS/DebitoCreditoPISSearchView'), 'DebitoCreditoPISSearchView'),
    exact: true,
    path: '/mlf/debitoCreditoPISSearchView',
  },
  {
    component: createLoadable(() => import('./debitoCreditoPIS/DebitoCreditoPISEditView'), 'DebitoCreditoPISEditView'),
    exact: true,
    path: '/mlf/debitoCreditoPISSearchView/edit',
  },
  {
    component: createLoadable(() => import('./debitoCreditoIRRF/DebitoCreditoIRRFSearchView'), 'DebitoCreditoIRRFSearchView'),
    exact: true,
    path: '/mlf/debitoCreditoIRRFSearchView',
  },
  {
    component: createLoadable(() => import('./debitoCreditoIRRF/DebitoCreditoIRRFEditView'), 'DebitoCreditoIRRFEditView'),
    exact: true,
    path: '/mlf/debitoCreditoIRRFSearchView/edit',
  },
  {
    component: createLoadable(() => import('./debitoCreditoCSLL/DebitoCreditoCSLLSearchView'), 'DebitoCreditoCSLLSearchView'),
    exact: true,
    path: '/mlf/debitoCreditoCSLLSearchView',
  },
  {
    component: createLoadable(() => import('./debitoCreditoCSLL/DebitoCreditoCSLLEditView'), 'DebitoCreditoCSLLEditView'),
    exact: true,
    path: '/mlf/debitoCreditoCSLLSearchView/edit',
  },
  {
    component: createLoadable(() => import('./debitoCreditoICMSST/DebitoCreditoICMSSTSearchView'), 'DebitoCreditoICMSSTSearchView'),
    exact: true,
    path: '/mlf/debitoCreditoICMSSTSearchView',
  },
  {
    component: createLoadable(() => import('./debitoCreditoICMSST/DebitoCreditoICMSSTEditView'), 'DebitoCreditoICMSSTEditView'),
    exact: true,
    path: '/mlf/debitoCreditoICMSSTSearchView/edit',
  },
  {
    component: createLoadable(() => import('./debitoCreditoICMSSTSN/DebitoCreditoICMSSTSNSearchView'), 'DebitoCreditoICMSSTSNSearchView'),
    exact: true,
    path: '/mlf/debitoCreditoICMSSTSNSearchView',
  },
  {
    component: createLoadable(() => import('./debitoCreditoICMSSTSN/DebitoCreditoICMSSTSNEditView'), 'DebitoCreditoICMSSTSNEditView'),
    exact: true,
    path: '/mlf/debitoCreditoICMSSTSNSearchView/edit',
  },
  {
    component: createLoadable(() => import('./debitoCreditoICMS/DebitoCreditoICMSSearchView'), 'DebitoCreditoICMSSearchView'),
    exact: true,
    path: '/mlf/debitoCreditoICMSSearchView',
  },
  {
    component: createLoadable(() => import('./debitoCreditoICMS/DebitoCreditoICMSEditView'), 'DebitoCreditoICMSEditView'),
    exact: true,
    path: '/mlf/debitoCreditoICMSSearchView/edit',
  },
  {
    component: createLoadable(() => import('./debitoCreditoISS/DebitoCreditoISSSearchView'), 'DebitoCreditoISSSearchView'),
    exact: true,
    path: '/mlf/debitoCreditoISSSearchView',
  },
  {
    component: createLoadable(() => import('./debitoCreditoISS/DebitoCreditoISSEditView'), 'DebitoCreditoISSSearchView'),
    exact: true,
    path: '/mlf/debitoCreditoISSSearchView/edit',
  },
  {
    component: createLoadable(() => import('./report/registroEntradaSaida/RegistroEntradaReportView'), 'RegistroEntradaReportView'),
    exact: true,
    path: '/mlf/registroEntradaReportView',
  },
  {
    component: createLoadable(() => import('./report/registroEntradaSaida/RegistroSaidaReportView'), 'RegistroSaidaReportView'),
    exact: true,
    path: '/mlf/registroSaidaReportView',
  },
  {
    component: createLoadable(() => import('./retencaoFontePisCofins/RetencaoFontePisCofinsSearchView'), 'RetencaoFontePisCofinsSearchView'),
    exact: true,
    path: '/mlf/retencaoFontePisCofinsSearchView',
  },
  {
    component: createLoadable(() => import('./retencaoFontePisCofins/RetencaoFontePisCofinsEditView'), 'RetencaoFontePisCofinsEditView'),
    exact: true,
    path: '/mlf/retencaoFontePisCofinsSearchView/edit',
  },
  {
    component: createLoadable(() => import('./configGNRE/ConfigGNRESearchView'), 'ConfigGNRESearchView'),
    exact: true,
    path: '/mlf/configGNRESearchView',
  },
  {
    component: createLoadable(() => import('./configGNRE/ConfigGNREEditView'), 'ConfigGNREEditView'),
    path: '/mlf/configGNRESearchView/edit',
  },
  {
    component: createLoadable(() => import('./gerarInventario/GerarInventarioProcessView'), 'GerarInventarioProcessView'),
    path: '/mlf/gerarInventarioProcessView',
  },
  {
    component: createLoadable(() => import('./configSituacaoEspecialICMS/ConfigSituacaoEspecialICMSEditView'), 'ConfigSituacaoEspecialICMSEditView'),
    path: '/mlf/configSituacaoEspecialICMSSearchView/edit',
  },
  {
    component: createLoadable(
      () => import('./configSituacaoEspecialICMS/ConfigSituacaoEspecialICMSSearchView'),
      'ConfigSituacaoEspecialICMSSearchView'
    ),
    exact: true,
    path: '/mlf/configSituacaoEspecialICMSSearchView',
  },
  {
    component: createLoadable(() => import('./configSituacaoEspecialIPI/ConfigSituacaoEspecialIPIEditView'), 'ConfigSituacaoEspecialIPIEditView'),
    path: '/mlf/configSituacaoEspecialIPISearchView/edit',
  },
  {
    component: createLoadable(() => import('./configSituacaoEspecialIPI/ConfigSituacaoEspecialIPISearchView'), 'ConfigSituacaoEspecialIPISearchView'),
    exact: true,
    path: '/mlf/configSituacaoEspecialIPISearchView',
  },
  {
    component: createLoadable(() => import('./lancamento/LancamentoEntradaSearchView'), 'LancamentoEntradaSearchView'),
    exact: true,
    path: '/mlf/lancamentoEntradaSearchView',
  },
  {
    component: createLoadable(() => import('./lancamento/LancamentoEntradaEditView'), 'LancamentoEntradaEditView'),
    exact: true,
    path: '/mlf/lancamentoEntradaSearchView/edit',
  },
  {
    component: createLoadable(() => import('./lancamento/LancamentoSaidaSearchView'), 'LancamentoSaidaSearchView'),
    exact: true,
    path: '/mlf/lancamentoSaidaSearchView',
  },
  {
    component: createLoadable(() => import('./lancamento/LancamentoSaidaEditView'), 'LancamentoSaidaEditView'),
    exact: true,
    path: '/mlf/lancamentoSaidaSearchView/edit',
  },
  {
    component: createLoadable(() => import('./atividadeImobiliaria/AtividadeImobiliariaSearchView'), 'AtividadeImobiliariaSearchView'),
    exact: true,
    path: '/mlf/atividadeImobiliariaSearchView',
  },
  {
    component: createLoadable(() => import('./atividadeImobiliaria/AtividadeImobiliariaEditView'), 'AtividadeImobiliariaEditView'),
    exact: true,
    path: '/mlf/atividadeImobiliariaSearchView/edit',
  },
  {
    component: createLoadable(() => import('../ger/contaPagarReceber/ContaReceberSearchView'), 'ContaReceberSearchView'),
    exact: true,
    path: '/mlf/contaReceberSearchView',
  },
  {
    component: createLoadable(() => import('../ger/contaPagarReceber/ContaReceberEditView'), 'ContaReceberEditView'),
    path: '/mlf/contaReceberSearchView/edit',
  },
  {
    component: createLoadable(
      () => import('../ger/contaPagarReceber/ContaPagarReceberParcelaContabilizacaoEditView'),
      'ContaPagarReceberParcelaContabilizacaoEditView'
    ),
    exact: true,
    path: '/mlf/contaPagarReceberParcelaContabilizacaoEditView',
  },
  {
    component: createLoadable(() => import('../ger/contaPagarReceber/ContaPagarSearchView'), 'ContaPagarSearchView'),
    exact: true,
    path: '/mlf/contaPagarSearchView',
  },
  {
    component: createLoadable(() => import('../ger/contaPagarReceber/ContaPagarEditView'), 'ContaPagarEditView'),
    path: '/mlf/contaPagarSearchView/edit',
  },
  {
    component: createLoadable(() => import('./servico/ServicoSearchView'), 'ServicoSearchView'),
    exact: true,
    path: '/mlf/servicoSearchView',
  },
  {
    component: createLoadable(() => import('./servico/ServicoEditView'), 'ServicoEditView'),
    path: '/mlf/servicoSearchView/edit',
  },
  {
    component: createLoadable(() => import('./servico/ServicoReinfSearchView'), 'ServicoReinfSearchView'),
    exact: true,
    path: '/mlf/servicoReinfSearchView',
  },
  {
    component: createLoadable(() => import('./servico/ServicoReinfEditView'), 'ServicoReinfEditView'),
    path: '/mlf/ServicoReinfSearchView/edit',
  },
  {
    component: createLoadable(
      () => import('./importacaoSintegraMLFMultiplo/ImportacaoSintegraMLFMultiploProcessView'),
      'ImportacaoSintegraMLFMultiploProcessView'
    ),
    exact: true,
    path: '/mlf/importacaoSintegraMLFMultiploProcessView',
  },
  {
    component: createLoadable(() => import('./importacaoSpedEFD/ImportacaoSpedFiscalProcessView'), 'ImportacaoSpedFiscalProcessView'),
    exact: true,
    path: '/mlf/importacaoSpedFiscalProcessView',
  },
  {
    component: createLoadable(() => import('./informacaoComplementar/InformacaoComplementarSearchView'), 'InformacaoComplementarSearchView'),
    exact: true,
    path: '/mlf/informacaoComplementarSearchView',
  },
  {
    component: createLoadable(() => import('./informacaoComplementar/InformacaoComplementarEditView'), 'InformacaoComplementarEditView'),
    path: '/mlf/informacaoComplementarSearchView/edit',
  },
  {
    component: createLoadable(() => import('./operacaoCartao/OperacaoCartaoCreditoDebitoSearchView'), 'OperacaoCartaoCreditoDebitoSearchView'),
    exact: true,
    path: '/mlf/operacaoCartaoCreditoDebitoSearchView',
  },
  {
    component: createLoadable(() => import('./operacaoCartao/OperacaoCartaoCreditoDebitoEditView'), 'OperacaoCartaoCreditoDebitoEditView'),
    path: '/mlf/operacaoCartaoCreditoDebitoSearchView/edit',
  },
  {
    component: createLoadable(
      () => import('./configIntegracaoContabilCFOP/ConfigIntegracaoContabilCFOPSearchView'),
      'ConfigIntegracaoContabilCFOPSearchView'
    ),
    exact: true,
    path: '/mlf/configIntegracaoContabilCFOPSearchView',
  },
  {
    component: createLoadable(
      () => import('./configIntegracaoContabilCFOP/ConfigIntegracaoContabilCFOPEditView'),
      'ConfigIntegracaoContabilCFOPEditView'
    ),
    path: '/mlf/configIntegracaoContabilCFOPSearchView/edit',
  },
  {
    component: createLoadable(() => import('./recibo/ReciboSearchView'), 'ReciboSearchView'),
    exact: true,
    path: '/mlf/reciboSearchView',
  },
  {
    component: createLoadable(() => import('./recibo/ReciboEditView'), 'ReciboEditView'),
    path: '/mlf/reciboSearchView/edit',
  },
  {
    component: createLoadable(() => import('./configRecibo/ConfigReciboSearchView'), 'ConfigReciboSearchView'),
    exact: true,
    path: '/mlf/configReciboSearchView',
  },
  {
    component: createLoadable(() => import('./configRecibo/ConfigReciboEditView'), 'ConfigReciboEditView'),
    path: '/mlf/configReciboSearchView/edit',
  },
  {
    component: createLoadable(() => import('./vafOutrasEntradasMLF/VafOutrasEntradasMLFSearchView'), 'VafOutrasEntradasMLFSearchView'),
    exact: true,
    path: '/mlf/vafOutrasEntradasMLFSearchView',
  },
  {
    component: createLoadable(() => import('./vafOutrasEntradasMLF/VafOutrasEntradasMLFEditView'), 'VafOutrasEntradasMLFEditView'),
    path: '/mlf/vafOutrasEntradasMLFSearchView/edit',
  },
  {
    component: createLoadable(() => import('./damefVafEntradaMLF/DamefVafEntradaMLFSearchView'), 'DamefVafEntradaMLFSearchView'),
    exact: true,
    path: '/mlf/damefVafEntradaMLFSearchView',
  },
  {
    component: createLoadable(() => import('./damefVafEntradaMLF/DamefVafEntradaMLFEditView'), 'DamefVafEntradaMLFEditView'),
    path: '/mlf/damefVafEntradaMLFSearchView/edit',
  },
  {
    component: createLoadable(() => import('./damefVafSaidaMLF/DamefVafSaidaMLFSearchView'), 'DamefVafSaidaMLFSearchView'),
    exact: true,
    path: '/mlf/damefVafSaidaMLFSearchView',
  },
  {
    component: createLoadable(() => import('./damefVafSaidaMLF/DamefVafSaidaMLFEditView'), 'DamefVafSaidaMLFEditView'),
    path: '/mlf/damefVafSaidaMLFSearchView/edit',
  },
  {
    component: createLoadable(() => import('./importacaoProdutoCSV/ImportacaoProdutoCSVProcessView'), 'ImportacaoProdutoCSVProcessView'),
    exact: true,
    path: '/mlf/importacaoProdutoCSVProcessView',
  },
  {
    component: createLoadable(() => import('./importacaoConv1152003/ImportacaoConv1152003ProcessView'), 'ImportacaoConv1152003ProcessView'),
    exact: true,
    path: '/mlf/importacaoConv1152003ProcessView',
  },
  {
    component: createLoadable(() => import('./importacaoBPe/BPeImportacaoProcessView'), 'BPeImportacaoProcessView'),
    exact: true,
    path: '/mlf/bPeImportacaoProcessView',
  },
  {
    component: createLoadable(() => import('./importacaoCTe/CTeImportacaoProcessView'), 'CTeImportacaoProcessView'),
    exact: true,
    path: '/mlf/cTeImportacaoProcessView',
  },
  {
    component: createLoadable(() => import('./report/guiaDae/GuiaDaeReportView'), 'GuiaDaeReportView'),
    path: '/mlf/guiaDaeReportView',
  },
  {
    component: createLoadable(() => import('./report/apuracoes/ApuracaoIPIReportView'), 'ApuracaoIPIReportView'),
    path: '/mlf/apuracaoIPIReportView',
  },
  {
    component: createLoadable(() => import('./report/apuracoes/ApuracaoICMSReportView'), 'ApuracaoICMSReportView'),
    path: '/mlf/apuracaoICMSReportView',
  },
  {
    component: createLoadable(() => import('./report/apuracoes/ApuracaoISSReportView'), 'ApuracaoISSReportView'),
    path: '/mlf/apuracaoISSReportView',
  },
  {
    component: createLoadable(() => import('./report/movimentacoes/MovimentacoesReportView'), 'MovimentacoesReportView'),
    path: '/mlf/movimentacoesReportView',
  },
  {
    component: createLoadable(() => import('./report/tributosST/TributosReportView'), 'TributosReportView'),
    path: '/mlf/tributosReportView',
  },
  {
    component: createLoadable(
      () => import('../ger/conhecimentoTransporte/ConhecimentoTransporteEntradaSearchView'),
      'ConhecimentoTransporteEntradaSearchView'
    ),
    exact: true,
    path: '/mlf/conhecimentoTransporteEntradaSearchView',
  },
  {
    component: createLoadable(
      () => import('../ger/conhecimentoTransporte/ConhecimentoTransporteSaidaSearchView'),
      'ConhecimentoTransporteSaidaSearchView'
    ),
    exact: true,
    path: '/mlf/conhecimentoTransporteSaidaSearchView',
  },
  {
    component: createLoadable(
      () => import('./configIntegracaoContabilForcli/ConfigIntegracaoContabilForcliSearchView'),
      'ConfigIntegracaoContabilForcliSearchView'
    ),
    exact: true,
    path: '/mlf/configIntegracaoContabilForcliSearchView',
  },
  {
    component: createLoadable(
      () => import('./configIntegracaoContabilForcli/ConfigIntegracaoContabilForcliEditView'),
      'ConfigIntegracaoContabilForcliEditView'
    ),
    path: '/mlf/configIntegracaoContabilForcliSearchView/edit',
  },
  {
    component: createLoadable(
      () => import('./configIntegracaoContabilContaPagarReceber/ConfigIntegracaoContabilContaPagarReceberEditView'),
      'ConfigIntegracaoContabilContaPagarReceberEditView'
    ),
    path: '/mlf/configIntegracaoContabilContaPagarReceberEditView',
  },
  {
    component: createLoadable(
      () => import('./lancamentoDiarioCombustivel/LancamentoDiarioCombustivelEditView'),
      'LancamentoDiarioCombustivelEditView'
    ),
    path: '/mlf/lancamentoDiarioCombustivelSearchView/edit',
  },
  {
    component: createLoadable(
      () => import('./lancamentoDiarioCombustivel/LancamentoDiarioCombustivelSearchView'),
      'LancamentoDiarioCombustivelSearchView'
    ),
    exact: true,
    path: '/mlf/lancamentoDiarioCombustivelSearchView',
  },
  {
    component: createLoadable(() => import('./bicoBombaCombustivel/BicoBombaCombustivelEditView'), 'BicoBombaCombustivelEditView'),
    path: '/mlf/bicoBombaCombustivelSearchView/edit',
  },
  {
    component: createLoadable(() => import('./bicoBombaCombustivel/BicoBombaCombustivelSearchView'), 'BicoBombaCombustivelSearchView'),
    exact: true,
    path: '/mlf/bicoBombaCombustivelSearchView',
  },
  {
    component: createLoadable(() => import('./bombaCombustivel/BombaCombustivelEditView'), 'BombaCombustivelEditView'),
    path: '/mlf/bombaCombustivelSearchView/edit',
  },
  {
    component: createLoadable(() => import('./bombaCombustivel/BombaCombustivelSearchView'), 'BombaCombustivelSearchView'),
    exact: true,
    path: '/mlf/bombaCombustivelSearchView',
  },
  {
    component: createLoadable(() => import('./armazenamentoCombustivel/ArmazenamentoCombustivelEditView'), 'ArmazenamentoCombustivelEditView'),
    path: '/mlf/armazenamentoCombustivelSearchView/edit',
  },
  {
    component: createLoadable(() => import('./armazenamentoCombustivel/ArmazenamentoCombustivelSearchView'), 'ArmazenamentoCombustivelSearchView'),
    exact: true,
    path: '/mlf/armazenamentoCombustivelSearchView',
  },
  {
    component: createLoadable(() => import('./contadorBicoBomba/ContadorBicoBombaCombustivelEditView'), 'ContadorBicoBombaCombustivelEditView'),
    path: '/mlf/contadorBicoBombaCombustivelSearchView/edit',
  },
  {
    component: createLoadable(() => import('./contadorBicoBomba/ContadorBicoBombaCombustivelSearchView'), 'ContadorBicoBombaCombustivelSearchView'),
    exact: true,
    path: '/mlf/contadorBicoBombaCombustivelSearchView',
  },
  {
    component: createLoadable(() => import('./encerranteBombaCombustivel/EncerranteBombaCombustivelEditView'), 'EncerranteBombaCombustivelEditView'),
    path: '/mlf/encerranteBombaCombustivelSearchView/edit',
  },
  {
    component: createLoadable(
      () => import('./encerranteBombaCombustivel/EncerranteBombaCombustivelSearchView'),
      'EncerranteBombaCombustivelSearchView'
    ),
    exact: true,
    path: '/mlf/encerranteBombaCombustivelSearchView',
  },
  {
    component: createLoadable(
      () => import('./fechamentoEstoqueCombustivel/FechamentoEstoqueCombustivelEditView'),
      'FechamentoEstoqueCombustivelEditView'
    ),
    path: '/mlf/fechamentoEstoqueCombustivelSearchView/edit',
  },
  {
    component: createLoadable(
      () => import('./fechamentoEstoqueCombustivel/FechamentoEstoqueCombustivelSearchView'),
      'FechamentoEstoqueCombustivelSearchView'
    ),
    exact: true,
    path: '/mlf/fechamentoEstoqueCombustivelSearchView',
  },
  {
    component: createLoadable(() => import('./dipjConfiguracao/DIPJConfiguracaoMLFView'), 'DIPJConfiguracaoMLFView'),
    exact: true,
    path: '/mlf/DIPJConfiguracaoMLFView',
  },
  {
    component: createLoadable(
      () => import('./configIntegracaoContabilServicos/ConfigIntegracaoContabilServicosEditView'),
      'ConfigIntegracaoContabilServicosEditView'
    ),
    path: '/mlf/configIntegracaoContabilServicosEditView',
  },
  {
    component: createLoadable(
      () => import('./configIntegracaoContabilImpostos/ConfigIntegracaoContabilImpostosEditView'),
      'ConfigIntegracaoContabilImpostosEditView'
    ),
    path: '/mlf/configIntegracaoContabilImpostosEditView',
  },
  {
    component: createLoadable(
      () => import('./debitoCreditoSimplesNacional/DebitoCreditoSimplesNacionalSearchView'),
      'DebitoCreditoSimplesNacionalSearchView'
    ),
    exact: true,
    path: '/mlf/debitoCreditoSimplesNacionalSearchView',
  },
  {
    component: createLoadable(
      () => import('./debitoCreditoSimplesNacional/DebitoCreditoSimplesNacionalEditView'),
      'DebitoCreditoSimplesNacionalEditView'
    ),
    path: '/mlf/debitoCreditoSimplesNacionalSearchView/edit',
  },
  {
    component: createLoadable(() => import('./excluirNotaPeriodo/ExcluirEntradaPeriodoProcessView'), 'ExcluirEntradaPeriodoProcessView'),
    path: '/mlf/excluirEntradaPeriodoProcessView',
  },
  {
    component: createLoadable(() => import('./excluirNotaPeriodo/ExcluirSaidaPeriodoProcessView'), 'ExcluirSaidaPeriodoProcessView'),
    path: '/mlf/excluirSaidaPeriodoProcessView',
  },
  {
    component: createLoadable(() => import('./report/registroSaidaSerie/RegistroSaidaSerieReportView'), 'RegistroSaidaSerieReportView'),
    path: '/mlf/registroSaidaSerieReportView',
  },
  {
    component: createLoadable(() => import('./report/aquisicaoProdutorRural/AquisicaoProdutorRuralReportView'), 'AquisicaoProdutorRuralReportView'),
    path: '/mlf/aquisicaoProdutorRuralReportView',
  },
  {
    component: createLoadable(() => import('./report/guiaDarfMLF/GuiaDarfMLFReportView'), 'GuiaDarfMLFReportView'),
    path: '/mlf/guiaDarfMLFReportView',
  },
  {
    component: createLoadable(() => import('./report/contaPagarReceber/ContaPagarReportView'), 'ContaPagarReportView'),
    path: '/mlf/contaPagarReportView',
  },
  {
    component: createLoadable(() => import('./report/contaPagarReceber/ContaReceberReportView'), 'ContaReceberReportView'),
    path: '/mlf/contaReceberReportView',
  },
  {
    component: createLoadable(() => import('./importacaoNFP/ImportacaoNFPaulistaProcessView'), 'ImportacaoNFPaulistaProcessView'),
    exact: true,
    path: '/mlf/importacaoNFPaulistaProcessView',
  },
  {
    component: createLoadable(() => import('./importacaoNFe/NFeImportacaoXMLProcessView'), 'NFeImportacaoXMLProcessView'),
    exact: true,
    path: '/mlf/nfeImportacaoXMLProcessView',
  },
  {
    component: createLoadable(() => import('./calcularImpostoProcessView/CalcularImpostoProcessView'), 'CalcularImpostoProcessView'),
    exact: true,
    path: '/mlf/calcularImpostoProcessView',
  },
  {
    component: createLoadable(() => import('./report/recibosEFDReinf/ReciboEFDReinfReportView'), 'ReciboEFDReinfReportView'),
    path: '/mlf/reciboEFDReinfReportView',
  },
  {
    component: createLoadable(() => import('./exportacaoReinf/ExportacaoReinfProcessView'), 'ExportacaoReinfProcessView'),
    exact: true,
    path: '/mlf/exportacaoReinfProcessView',
  },
  {
    component: createLoadable(() => import('./empresaReinfConfig/EmpresaReinfConfigView'), 'EmpresaReinfConfig'),
    exact: true,
    path: '/mlf/empresaReinfConfig',
  },
  {
    component: createLoadable(() => import('./importacaoEntrada/ImportacaoEntradaSearchView'), 'ImportacaoEntradaSearchView'),
    exact: true,
    path: '/mlf/importacaoEntradaSearchView',
  },
  {
    component: createLoadable(() => import('./importacaoEntrada/ImportacaoEntradaEditView'), 'ImportacaoEntradaEditView'),
    path: '/mlf/importacaoEntradaSearchView/edit',
  },
  {
    component: createLoadable(() => import('./exportacaoSaida/ExportacaoSaidaSearchView'), 'ExportacaoSaidaSearchView'),
    exact: true,
    path: '/mlf/exportacaoSaidaSearchView',
  },
  {
    component: createLoadable(() => import('./exportacaoSaida/ExportacaoSaidaEditView'), 'ExportacaoSaidaEditView'),
    path: '/mlf/exportacaoSaidaSearchView/edit',
  },
  {
    component: createLoadable(() => import('./importacaoNFSeMunicipio/ImportacaoNFSeMunicipioProcessView'), 'ImportacaoNFSeMunicipioProcessView'),
    exact: true,
    path: '/mlf/importacaoNFSeMunicipioProcessView',
  },
  {
    component: createLoadable(
      () => import('./importacaoNFSeMarilia/NFSeMunicipioMariliaImportacaoLoteView'),
      'NFSeMunicipioMariliaImportacaoLoteView'
    ),
    exact: true,
    path: '/mlf/nfseMunicipioMariliaImportacaoLoteView',
  },
  {
    component: createLoadable(() => import('./baixarContaPagarReceber/BaixarContaPagarReceberSearchView'), 'BaixarContaPagarReceberSearchView'),
    exact: true,
    path: '/mlf/baixarContaPagarReceberSearchView',
  },
  {
    component: createLoadable(() => import('./pgDas/PgDasProcessView'), 'PgDasProcessView'),
    exact: true,
    path: '/mlf/pgDasProcessView',
  },
  {
    component: createLoadable(() => import('./resumoMovimentoDiario/MovimentoDiarioSearchView'), 'MovimentoDiarioSearchView'),
    exact: true,
    path: '/mlf/movimentoDiarioSearchView',
  },
  {
    component: createLoadable(() => import('./resumoMovimentoDiario/MovimentoDiarioEditView'), 'MovimentoDiarioEditView'),
    path: '/mlf/movimentoDiarioSearchView/edit',
  },
  {
    component: createLoadable(() => import('./resumoMovimentoDiario/movimentoDiarioDocs/MovimentoDiarioDocsEditView'), 'MovimentoDiarioDocsEditView'),
    path: '/mlf/movimentoDiarioSearchView/edit/movimentoDiarioDocsEditView',
  },
  {
    component: createLoadable(() => import('./manifestacaoDestinatario/ManifestacaoDestinatarioProcessView'), 'ManifestacaoDestinatarioProcessView'),
    exact: true,
    path: '/mlf/manifestacaoDestinatarioProcessView',
  },
  {
    component: createLoadable(() => import('./baixaAtivoProcessView/BaixaAtivoProcessView'), 'BaixaAtivoProcessView'),
    path: '/mlf/baixaAtivoProcessView',
  },
  {
    component: createLoadable(() => import('./monitorEventoReinf/MonitorEventoReinfProcessView'), 'MonitorEventoReinfProcessView'),
    exact: true,
    path: '/mlf/monitorEventoReinfProcessView',
  },
  {
    component: createLoadable(
      () => import('../ger/conhecimentoTransporte/ConhecimentoTransporteEntradaEditView'),
      'ConhecimentoTransporteEntradaEditView'
    ),
    path: '/mlf/conhecimentoTransporteEntradaSearchView/edit',
  },
  {
    component: createLoadable(
      () => import('../ger/conhecimentoTransporte/ConhecimentoTransporteSaidaEditView'),
      'ConhecimentoTransporteSaidaEditView'
    ),
    path: '/mlf/conhecimentoTransporteSaidaSearchView/edit',
  },
  {
    component: createLoadable(() => import('./importacaoForcliExcel/ImportacaoForcliExcelProcessView'), 'ImportacaoForcliExcelProcessView'),
    exact: true,
    path: '/mlf/importacaoForcliExcelProcessView',
  },
  {
    component: createLoadable(
      () => import('./importacaoInventarioExcel/ImportacaoInventarioExcelProcessView'),
      'ImportacaoInventarioExcelProcessView'
    ),
    exact: true,
    path: '/mlf/importacaoInventarioExcelProcessView',
  },
  {
    component: createLoadable(() => import('./rastrearNota/RastrearNotaEmpresaProdutoSearchView'), 'RastrearNotaEmpresaProdutoSearchView'),
    path: '/mlf/rastrearNotaEmpresaProdutoSearchView',
  },
  {
    component: createLoadable(() => import('./defis/DefisProcessView'), 'DefisProcessView'),
    exact: true,
    path: '/mlf/defisProcessView',
  },
  {
    component: createLoadable(
      () => import('./baixaContasPagarExcel/BaixaContasPagarReceberExcelProcessView'),
      'BaixaContasPagarReceberExcelProcessView'
    ),
    exact: true,
    path: '/mlf/baixaContasPagarReceberExcelProcessView',
  },
  {
    component: createLoadable(
      () => import('./report/relacaoEntradaSaida/codigoFiscalNCM/listagem/RelacaoCodigoFiscalNCMEntradaReportView'),
      'RelacaoCodigoFiscalNCMEntradaReportView'
    ),
    path: '/mlf/relacaoCodigoFiscalNCMEntradaReportView',
  },
  {
    component: createLoadable(
      () => import('./report/relacaoEntradaSaida/codigoFiscalNCM/listagem/RelacaoCodigoFiscalNCMSaidaReportView'),
      'RelacaoCodigoFiscalNCMSaidaReportView'
    ),
    path: '/mlf/relacaoCodigoFiscalNCMSaidaReportView',
  },
  {
    component: createLoadable(
      () => import('./report/resumoMovimentacaoSTEntradaSaida/ResumoMovimentacaoSTEntradaSaida'),
      'ResumoMovimentacaoSTEntradaSaida'
    ),
    path: '/mlf/resumoMovimentacaoSTEntradaSaida',
  },
  {
    component: createLoadable(() => import('./report/recibo/ReciboReportView'), 'ReciboReportView'),
    exact: true,
    path: '/mlf/ReciboReportView',
  },
  {
    component: createLoadable(() => import('./report/entradaProduto/EntradaProdutoReportView'), 'EntradaProdutoReportView'),
    path: '/mlf/entradaProdutoReportView',
  },
  {
    component: createLoadable(() => import('./cfeSatImportacao/CfeSatImportacaoProcessView'), 'CfeSatImportacaoProcessView'),
    exact: true,
    path: '/mlf/cfeSatImportacaoProcessView',
  },
  {
    component: createLoadable(() => import('./nfceImportacao/NfceImportacaoProcessView'), 'NfceImportacaoProcessView'),
    exact: true,
    path: '/mlf/nfceImportacaoProcessView',
  },
  {
    component: createLoadable(() => import('./report/defis/DefisReportView'), 'DefisReportView'),
    exact: true,
    path: '/mlf/DefisReportView',
  },
  {
    component: createLoadable(
      () => import('./importacaoAtividadeImobiliariaExcel/ImportacaoAtividadeImobiliariaExcelProcessView'),
      'ImportacaoAtividadeImobiliariaExcelProcessView'
    ),
    exact: true,
    path: '/mlf/importacaoAtividadeImobiliariaExcelProcessView',
  },
  {
    component: createLoadable(() => import('./baixarImposto/BaixarImpostoProcessView'), 'BaixarImpostoProcessView'),
    exact: true,
    path: '/mlf/baixarImpostoProcessView',
  },
  {
    component: createLoadable(() => import('./exclusaoIcmsPisCofins/ExclusaoIcmsPisCofinsProcessView'), 'ExclusaoIcmsPisCofinsProcessView'),
    exact: true,
    path: '/mlf/exclusaoIcmsPisCofinsProcessView',
  },
  {
    component: createLoadable(
      () => import('./report/listagemPrestacaoServicoEntradaSaida/ListagemPrestacaoServicoEntradaReportView'),
      'ListagemPrestacaoServicoEntradaReportView'
    ),
    exact: true,
    path: '/mlf/listagemPrestacaoServicoEntradaReportView',
  },
  {
    component: createLoadable(
      () => import('./report/listagemPrestacaoServicoEntradaSaida/ListagemPrestacaoServicoSaidaReportView'),
      'ListagemPrestacaoServicoSaidaReportView'
    ),
    exact: true,
    path: '/mlf/listagemPrestacaoServicoSaidaReportView',
  },
  {
    component: createLoadable(() => import('./report/exportacoesExtras/ExportacoesExtrasReportView'), 'ExportacoesExtrasReportView'),
    exact: true,
    path: '/mlf/exportacoesExtrasReportView',
  },
  {
    component: createLoadable(() => import('./report/tributacaoMonofasica/TributacaoMonofasicaReportView'), 'TributacaoMonofasicaProcessView'),
    exact: true,
    path: '/mlf/tributacaoMonofasicaProcessView',
  },
  {
    component: createLoadable(() => import('../mlf/naturezaRendimento/NaturezaRendimentoEditView'), 'NaturezaRendimentoEditView'),
    path: '/mlf/naturezaRendimentoSearchView/edit',
  },
  {
    component: createLoadable(() => import('../mlf/naturezaRendimento/NaturezaRendimentoSearchView'), 'NaturezaRendimentoSearchView'),
    exact: true,
    path: '/mlf/naturezaRendimentoSearchView',
  },
  {
    component: createLoadable(() => import('./report/entradaICMSSTRetido/EntradaICMSSTSubstitutoReportView'), 'EntradaICMSSTRetidoReportView'),
    exact: true,
    path: '/mlf/EntradaICMSSTSubstitutoReportView',
  },
  {
    component: createLoadable(() => import('./report/saidaICMSEfetivo/SaidaICMSEfetivoReportView'), 'SaidaICMSSTEfetivoReportView'),
    exact: true,
    path: '/mlf/SaidaICMSEfetivoReportView',
  },
  {
    component: createLoadable(
      () => import('./report/registroApuracaoICMSSTSimplesNacional/registroApuracaoICMSSTSimplesNacionalReportView'),
      'RegistroApuracaoICMSSTSimplesNacionalReportView'
    ),
    exact: true,
    path: '/mlf/RegistroApuracaoICMSSTSimplesNacionalReportView',
  },
  {
    component: createLoadable(() => import('./faturamentoComprasGraf/GraficoFaturamentoComprasProcessView'), 'GraficoFaturamentoComprasProcessView'),
    exact: true,
    path: '/mlf/graficoFaturamentoComprasProcessView',
  },
  {
    component: createLoadable(() => import('./importacaoReciboExcel/ImportacaoReciboExcelProcessView'), 'ImportacaoReciboExcelProcessView'),
    exact: true,
    path: '/mlf/importacaoReciboExcelProcessView',
  },
  {
    component: createLoadable(() => import('./informacaoPlanoSaudeMLF/InformacaoPlanoSaudeMLFEditView'), 'InformacaoPlanoSaudeMLFEditView'),
    path: '/mlf/informacaoPlanoSaudeMLFSearchView/edit',
  },
  {
    component: createLoadable(() => import('./informacaoPlanoSaudeMLF/InformacaoPlanoSaudeMLFSearchView'), 'InformacaoPlanoSaudeMLFSearchView'),
    exact: true,
    path: '/mlf/informacaoPlanoSaudeMLFSearchView',
  },
  {
    component: createLoadable(() => import('./estoqueEscriturado/EstoqueEscrituradoSearchView'), 'EstoqueEscrituradoSearchView'),
    exact: true,
    path: '/mlf/estoqueEscrituradoSearchView',
  },
  {
    component: createLoadable(() => import('./estoqueEscriturado/EstoqueEscrituradoEditView'), 'EstoqueEscrituradoEditView'),
    exact: true,
    path: '/mlf/estoqueEscrituradoSearchView/edit',
  },
  {
    component: createLoadable(() => import('./estoqueEscriturado/EstoqueEscrituradoProdutoEditView'), 'EstoqueEscrituradoProdutoEditView'),
    path: '/mlf/estoqueEscrituradoSearchView/edit',
  },
  {
    component: createLoadable(() => import('./importacaoK200/ImportacaoK200ProcessView'), 'ImportacaoK200ProcessView'),
    exact: true,
    path: '/mlf/importacaoK200ProcessView',
  },
  {
    component: createLoadable(
      () => import('./report/compraVendaProduto/CompraVendaProdutoEntradaSaidaReportView'),
      'CompraVendaProdutoEntradaSaidaReportView'
    ),
    exact: true,
    path: '/mlf/compraVendaProdutoEntradaSaidaReportView',
  },
  {
    component: createLoadable(() => import('./report/relacaoExportacao/RelacaoExportacaoEntradaReportView'), 'RelacaoExportacaoEntradaReportView'),
    exact: true,
    path: '/mlf/relacaoExportacaoEntradaReportView',
  },
  {
    component: createLoadable(() => import('./report/relacaoExportacao/RelacaoExportacaoSaidaReportView'), 'RelacaoExportacaoSaidaReportView'),
    exact: true,
    path: '/mlf/relacaoExportacaoSaidaReportView',
  },
];

export default mlfRoutes;
