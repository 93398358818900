import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { CSSProperties, FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Button } from 'reactstrap';
import { ERROR, showToast } from 'summer';
import mudancaMenuReinf from '../../../assets/mudancaMenuReinf.gif';
import { salvarUsuarioVisualizouTour } from '../../../services/ger.service';
import { Reducers } from '../../../store/ducks';
import { fecharTourReinf, toggleMobileSidebar } from '../../../store/ducks/global.duck';
import './ReinfTour.css';

const ReinfTour: FC = () => {
  const {
    globalReducer: { exibirTourReinf, globalParameter, primeiraVezTourReinf, dataTestId, isEnvio },
  } = useSelector<Reducers, Reducers>(state => state);
  const dispatch = useDispatch();
  const history = useHistory();
  const [indexTourActive, setIndexTourActive] = useState(0);
  const [isTourInitialized, setIsTourInitialized] = useState(false);

  const testId =
    dataTestId && typeof dataTestId === 'object' && 'dataTestId' in dataTestId ? (dataTestId as { dataTestId: string }).dataTestId : dataTestId || '';
  const urlMonitor = '/mlf/monitorEventoReinfProcessView';
  const isMobile = () => window.innerWidth < 1100;
  const getSelector = (): any => document.querySelector(`[data-testid="${testId}"]`);

  const getEnviarSelector = () => {
    return isMobile() ? document.querySelector('#enviarReinfMobile') : document.querySelector('#dockEnviar');
  };

  const STEPS = [
    {
      content: (
        <>
          <h4 className="font-weight-bold">O envio do Reinf mudou de lugar!</h4>
          <img className="mt-2" style={{ width: 250 }} src={mudancaMenuReinf} alt="Novo acesso Reinf" />
          <div className="mt-2 text-dark">
            Agora você envia o Reinf através do
            <br />
            Monitor de eventos.
          </div>
        </>
      ),
      customClick: (history, hideTour) => {
        if (history.location.pathname !== urlMonitor) {
          hideTour();
          history.push('/mlf/monitorEventoReinfProcessView', { abrirTour: true });
        }
      },
      position: 'left',
      selector: () => getSelector(),
    },
    {
      content: (
        <>
          <h4 className="font-weight-bold">Agora você envia o Reinf aqui</h4>
          <div className="text-dark">Junto de outras novidades neste mesmo menu!</div>
        </>
      ),
      position: 'right',
      selector: () => getEnviarSelector(),
    },
  ];

  const currentStep = STEPS[indexTourActive];

  useEffect(() => {
    if (testId === 'efd-reinf' && isEnvio === true) {
      if (exibirTourReinf) {
        if (history.location.pathname === urlMonitor) {
          getSelector().scrollIntoView({ block: 'center' });
          setIsTourInitialized(true);
        } else {
          history.push(urlMonitor);
          setTimeout(() => {
            setIsTourInitialized(true);
          }, 1200);
        }
      }
    } else {
      setIsTourInitialized(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exibirTourReinf, isEnvio]);

  useEffect(() => {
    if (exibirTourReinf) {
      if (isMobile()) {
        dispatch(toggleMobileSidebar());
      }
      window.scrollTo({ top: 0, behavior: 'smooth' });
      document.querySelector('html').style.overflow = 'hidden';
    } else {
      document.querySelector('html').style.overflow = 'auto';
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exibirTourReinf]);

  const iniciarTour = () => {
    if (!exibirTourReinf) {
      return;
    }

    const element = currentStep.selector && currentStep.selector();
    if (element) {
      const destaqueEl: any = document.querySelector('.destaque');
      const color = '#FFF';
      if (indexTourActive === 0) {
        document.querySelector('.destaque').innerHTML = '';
        destaqueEl.style.border = `1px solid ${color}`;
        destaqueEl.style.background = 'transparent';
      } else {
        destaqueEl.innerHTML = element.outerHTML;
        destaqueEl.style.background = color;
      }
    } else {
      document.querySelector('.destaque').innerHTML = '';
    }
  };

  useEffect(() => {
    if (isTourInitialized) {
      iniciarTour();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTourInitialized, indexTourActive]);

  const hideTour = () => {
    dispatch(fecharTourReinf());
  };

  const fechar = () => {
    setIndexTourActive(0);
    hideTour();
    setIsTourInitialized(false);
    if (primeiraVezTourReinf) {
      salvarUsuarioVisualizouTour(globalParameter.usuario.id, 'MUDANCAS_REINF', {
        errorFunction: message => showToast(message, ERROR),
        thenFunction: () => null,
      });
    }
  };

  const getTopPx = (alignVerticalCenter, extraPx) => {
    let topPx = 0;
    const element = currentStep.selector();
    if (element) {
      topPx = element.getBoundingClientRect().top + element.getBoundingClientRect().height / (alignVerticalCenter ? 2 : 1) + extraPx;
    }
    return topPx;
  };

  const getLeftRightPx = (dimension, extraPx) => {
    const element = currentStep.selector();
    return element ? element.getBoundingClientRect()[dimension] + extraPx : 0;
  };

  const getRightPx = () => {
    const element = currentStep.selector();
    return element ? element.getBoundingClientRect().right - element.getBoundingClientRect().left : 0;
  };

  const navigate = (index = null) => () => {
    let indexFmt = index === null ? indexTourActive : index;
    if (indexTourActive === 0 && index > 0) {
      indexFmt = 0;
    }

    const step = STEPS[indexFmt];
    if (step.customClick) {
      step.customClick(history, hideTour);
    }
    if (index === null) {
      if (indexTourActive === STEPS.length - 1) {
        fechar();
      } else {
        setIndexTourActive(p => p + 1);
      }
    } else {
      setIndexTourActive(index);
    }
    const { activeElement }: any = document;
    if (index === null && activeElement) {
      activeElement.blur();
    }
  };

  const getStyle = () => {
    const style: CSSProperties = {};
    if (isMobile()) {
      style.top = '50%';
      style.left = '50%';
      style.transform = 'translate(-50%, -50%)';
    } else {
      switch (currentStep.position) {
        case 'top-end':
          style.top = getTopPx(false, 30);
          style.right = getRightPx();
          break;
        case 'top':
          style.top = getTopPx(false, 30);
          style.left = getLeftRightPx('left', 50);
          break;
        case 'center':
          style.top = '50%';
          style.left = '50%';
          style.transform = 'translate(-50%, -50%)';
          break;
        case 'right':
          style.top = getLeftRightPx('top', -10);
          style.right = getLeftRightPx('width', 50);
          break;
        default:
          style.top = getTopPx(true, -150);
          style.left = getLeftRightPx('width', 20);
          break;
      }
    }
    return style;
  };

  const getDestaqueStyle = () => {
    const element: any = currentStep.selector && currentStep.selector();
    let style: any = {};
    if (element) {
      style = element.getBoundingClientRect().toJSON();
      if (indexTourActive === 1 || indexTourActive === 4) {
        style.width = 'max-content';
        style.height = 'max-content';
      }
    }
    return style;
  };

  if (!dataTestId) {
    return null;
  }

  return exibirTourReinf && isTourInitialized ? (
    <div className="tour-container">
      <div className="destaque" style={getDestaqueStyle()} />
      <div className={`tour-content arrow-${currentStep.position}`} style={getStyle()}>
        {(indexTourActive === STEPS.length - 1 || !primeiraVezTourReinf) && (
          <Button className="fechar-tour p-3" onClick={fechar} color="link">
            <FontAwesomeIcon icon="times" color="#C0C3CA" size="lg" />
          </Button>
        )}
        {currentStep.content}
        <Button size="sm" onClick={navigate()} color="primary" className="mt-3">
          Ok, entendi!
        </Button>
        <div className="steps">
          {Array.from({ length: STEPS.length }, (_, index) => (
            <span key={index} onClick={navigate(index)} className={`step ${index === indexTourActive ? 'active' : ''}`} />
          ))}
        </div>
      </div>
    </div>
  ) : null;
};
export default ReinfTour;
