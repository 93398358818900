import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'clsx';
import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { ERROR, showToast } from 'summer';
import { marcarVisualizouRetrospectiva, obterInfoRetrospectiva } from '../../../../services/ger.service';
import { Reducers } from '../../../../store/ducks';
import { GlobalState, setTaExibindoRetrospectiva } from '../../../../store/ducks/global.duck';
import { URL_RETROSPECTIVA, URL_RETROSPECTIVA_BANNER } from '../../../../utilities/wsUtil';

import './RetrospectivaView.css';

const RetrospectivaView: FC = () => {
  const { globalParameter, taExibindoRetrospectiva, modoExibicaoRetrospectiva } = useSelector<Reducers, GlobalState>(state => state.globalReducer);
  const dispatch = useDispatch();

  const [url, setUrl] = useState(null);
  const [frameOpen, setFrameOpen] = useState(false);

  useEffect(
    () => {
      if (globalParameter && taExibindoRetrospectiva) {
        obterInfoRetrospectiva({
          errorFunction: mensagem => showToast(mensagem, ERROR),
          thenFunction: info => {
            setUrl(
              `${URL_RETROSPECTIVA}?e=${info.totalEmpresas}&l=${info.totalRegistrosContabil}&n=${info.totalRegistrosFiscal}&f=${info.totalRegistrosFolha}`
            );
            setFrameOpen(modoExibicaoRetrospectiva === 2);
          },
        });
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [taExibindoRetrospectiva]
  );

  const close = () => {
    setFrameOpen(false);
    dispatch(setTaExibindoRetrospectiva(null));
  };

  const closeView = () => {
    close();
    marcarVisualizouRetrospectiva(globalParameter.usuario.id, {
      errorFunction: mensagem => showToast(mensagem, ERROR),
      thenFunction: () => null,
    });
  };

  const openFrame = () => setFrameOpen(true);

  return (
    <>
      <Modal
        className="modal-retrospectiva"
        centered={true}
        backdrop="static"
        size="lg"
        isOpen={taExibindoRetrospectiva && modoExibicaoRetrospectiva === 1}
        toggle={close}
      >
        <ModalHeader toggle={close} className="py-2">
          Retrospectiva
        </ModalHeader>
        <ModalBody className="py-0">
          <img src={URL_RETROSPECTIVA_BANNER} onClick={openFrame} alt="Banner Retrospectiva" />
        </ModalBody>
        <ModalFooter className="justify-content-between py-0 pb-1">
          <Button color="link" onClick={closeView} className="px-0 text-decoration-underline">
            Não exibir a retrospectiva novamente
          </Button>
          <Button size="sm" onClick={close} color="danger">
            <FontAwesomeIcon icon="times" />
            <span className="ml-2">Fechar</span>
          </Button>
        </ModalFooter>
      </Modal>
      {taExibindoRetrospectiva && (
        <div className={cx('retrospectiva-iframe-window', { frameOpen })}>
          {frameOpen && (
            <>
              <button className="retrospectiva-iframe-close" onClick={closeView}>
                Fechar <span className="icon">&#10006;</span>
              </button>
              <iframe title="Retrospectiva" src={url} sandbox="allow-downloads allow-same-origin allow-scripts" allow="web-share" />
            </>
          )}
        </div>
      )}
    </>
  );
};

export default RetrospectivaView;
