import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { createContext, FC, useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { alertManager, AlertType, ERROR, isEmpty, isNotEmpty, showToast } from 'summer';
import { Empresa } from '../models/dtos/ger/empresa';
import { BLOQUEAR_COM_SENHA, BLOQUEAR_TOTALMENTE } from '../models/enumerated/ger/permissoesLancamentosUsuarioEnum';
import { findCompleteEmpresaById } from '../services/ger.service';
import { Reducers } from '../store/ducks';
import { GlobalState } from '../store/ducks/global.duck';
import { validatePeriod } from '../utilities/ger.util';
import ModalSenhaAdministrador from '../views/components/modalSenhaAdministrador/ModalSenhaAdministrador';

interface LockAdminProviderHook {
  validate: (validateDate?: string | string[], blockedModule?: string, message?: string, idEmpresa?: any | any[]) => Promise<void>;
  validateModulos: (
    moduloPrimario: string,
    moduloSecundario: string,
    validateDate: any,
    mensagem: string,
    validateEmpresaModuloPrimario?: Empresa,
    validateEmpresaModuloSecundario?: Empresa
  ) => Promise<boolean>;
}

const LockAdminContext = createContext<LockAdminProviderHook>(null);

export const useLockAdmin = () => useContext(LockAdminContext);

export const LockAdminProvider: FC = ({ children }) => {
  const { globalParameter, currentState } = useSelector<Reducers, GlobalState>(state => state.globalReducer);

  const [modalSenha, setModalSenha] = useState(null);

  const close = () => {
    setModalSenha(null);
  };

  const allDatesValid = (validateDate, usedModule, empresa) => {
    const datesToValidate = Array.isArray(validateDate) ? validateDate : [validateDate];
    const periodoBloqueio = globalParameter.usuario[`permissoes${usedModule}`].periodoBloqueioLancamento;

    return datesToValidate.every(date => !validatePeriod(date, periodoBloqueio, usedModule, empresa));
  };

  const existValidateDate = (validateDate, usedModule, lockUsed, idEmpresa, resolve) => {
    const datesToValidate = Array.isArray(validateDate) ? validateDate : [validateDate];

    const handleValidation = validateEmpresa => {
      if (allDatesValid(datesToValidate, usedModule, validateEmpresa)) {
        resolve();
      } else {
        lockUsed();
      }
    };

    if (idEmpresa) {
      findCompleteEmpresaById(idEmpresa, {
        errorFunction: mensagem => {
          showToast(mensagem, ERROR);
        },
        thenFunction: handleValidation,
      });
    } else {
      handleValidation(globalParameter.empresa);
    }
  };

  const existValidateDateMultEmp = (validateDate, usedModule, lockUsed, empresas, resolve) => {
    const empresasInvalidas = [];

    const handleValidation = empresa => {
      if (!allDatesValid(validateDate, usedModule, empresa)) {
        empresasInvalidas.push(empresa);
      }
    };

    const promises = empresas.map(
      empresaId =>
        new Promise<void>((resolveEmpresa, rejectEmpresa) => {
          findCompleteEmpresaById(empresaId, {
            errorFunction: mensagem => {
              showToast(mensagem, ERROR);
              rejectEmpresa();
            },
            thenFunction: empresa => {
              handleValidation(empresa);
              resolveEmpresa();
            },
          });
        })
    );

    Promise.all(promises)
      .then(() => {
        if (empresasInvalidas.length > 0) {
          lockUsed(empresasInvalidas);
        } else {
          resolve();
        }
      })
      .catch(() => {
        lockUsed();
      });
  };

  const validate = (validateDate, blockedModule, message, idEmpresa) => {
    return new Promise<void>((resolve, reject) => {
      const usedModule = isNotEmpty(blockedModule) ? blockedModule.toUpperCase() : currentState;
      const modulePermissions = globalParameter.usuario[`permissoes${usedModule}`];

      const cancel = () => {
        reject();
        close();
      };

      const passwordLock = (empresasInvalidas?: any[]) =>
        setModalSenha(
          <ModalSenhaAdministrador onClose={close} onCancel={cancel} submit={resolve} message={message} invalidEmp={empresasInvalidas} />
        );

      const lockAlert = (empresasInvalidas?: any[]) => {
        alertManager.emit({
          message: (
            <div>
              <div className="mb-1 d-flex">
                <FontAwesomeIcon className="mr-1 mt-1" color={'#F4762D'} icon="exclamation-triangle" size="lg" />
                <span className="font-weight-bold font-size-lg">Bloqueado pelo Administrador.</span>
              </div>
              {message ? <span>{message}</span> : <span>Este processo está bloqueado para o usuário no período atual.</span>}
              {empresasInvalidas && (
                <>
                  <br />
                  <span>
                    As seguintes empresas estão fora do período de bloqueio deste usuário:{' '}
                    <strong>{empresasInvalidas.map(emp => emp.razaoSocial).join(', ')}</strong>
                  </span>
                </>
              )}
            </div>
          ),
          onOkClick: () => {
            cancel();
          },
          type: AlertType.INFORMATION,
        });
      };

      if (modulePermissions && modulePermissions.tipoBloqueioLancamento === BLOQUEAR_COM_SENHA.value) {
        if (validateDate) {
          if (idEmpresa && idEmpresa.length > 1) {
            existValidateDateMultEmp(validateDate, usedModule, passwordLock, idEmpresa, resolve);
          } else {
            existValidateDate(validateDate, usedModule, passwordLock, idEmpresa, resolve);
          }
        } else {
          passwordLock();
        }
      } else if (modulePermissions && modulePermissions.tipoBloqueioLancamento === BLOQUEAR_TOTALMENTE.value) {
        if (validateDate) {
          if (idEmpresa && idEmpresa.length > 1) {
            existValidateDateMultEmp(validateDate, usedModule, lockAlert, idEmpresa, resolve);
          } else {
            existValidateDate(validateDate, usedModule, lockAlert, idEmpresa, resolve);
          }
        } else {
          lockAlert();
        }
      } else {
        resolve();
      }
    });
  };

  const validateModulos = (
    moduloPrimario,
    moduloSecundario,
    validateDate,
    nomeOperacaoModuloSecundario,
    empresaModuloPrimario,
    empresaModuloSecundario
  ) => {
    return new Promise<boolean>((resolve, reject) => {
      const empresaValidateModuloPrimario = isEmpty(empresaModuloPrimario) ? globalParameter.empresa : empresaModuloPrimario;
      const empresaValidateModuloSecundario = isEmpty(empresaModuloSecundario) ? globalParameter.empresa : empresaModuloSecundario;

      validate(validateDate, moduloPrimario, null, empresaValidateModuloPrimario.id)
        .then(() => {
          const tipoBloqueioModuloPrimario = globalParameter.usuario[`permissoes${moduloPrimario}`].tipoBloqueioLancamento;
          const tipoBloqueioModuloSecundario = globalParameter.usuario[`permissoes${moduloSecundario}`].tipoBloqueioLancamento;

          const mensagemOperacaoBloqueada = (
            <>
              O seguinte processo está bloqueado para o usuário no período atual:
              <br />
              {nomeOperacaoModuloSecundario}
            </>
          );

          if (
            tipoBloqueioModuloPrimario === BLOQUEAR_COM_SENHA.value &&
            !allDatesValid(validateDate, moduloPrimario, empresaValidateModuloPrimario) &&
            tipoBloqueioModuloSecundario === BLOQUEAR_COM_SENHA.value
          ) {
            // Se chegou aqui é porque a senha já foi informada, que irá valer para os dois módulos, portanto não há necessidade de pedir novamente.
            resolve(true);
          } else {
            validate(validateDate, moduloSecundario, mensagemOperacaoBloqueada, empresaValidateModuloSecundario.id)
              .then(() => {
                resolve(true);
              })
              .catch(() => {
                resolve(false);
              });
          }
        })
        .catch(() => {
          reject();
        });
    });
  };

  return (
    <LockAdminContext.Provider value={{ validate, validateModulos }}>
      {modalSenha}
      {children}
    </LockAdminContext.Provider>
  );
};
