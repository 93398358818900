import { createAction, handleActions } from 'redux-actions';
import { currentDate, formatRef, MONTH_YEAR_PATTERN, refToFormat } from 'summer';
import { GlobalParameter } from '../../models/dtos/ger/globalParameter';
import { Retrospectiva } from '../../models/dtos/ger/retrospectiva';

export interface GlobalState {
  accessOldHonorarios: boolean;
  currentState: string;
  dataTestId: '';
  isEnvio: boolean;
  exibirAlteracaoSenha: boolean;
  exibirJanelaProcessos: boolean;
  exibirTourReinf: boolean;
  globalParameter: GlobalParameter;
  headerDrawerToggle: boolean;
  headerSearchHover: boolean;
  isCanary: boolean;
  menuModulosMobile: boolean;
  modoExibicaoRetrospectiva: number;
  primeiraVezTourReinf: boolean;
  retrospectiva: Retrospectiva;
  showModalPgDasIntegraContador: boolean;
  sidebarToggle: boolean;
  sidebarToggleMobile: boolean;
  systemVersion: string;
  taExibindoCertificadoVencido: boolean;
  taExibindoMensageria: boolean;
  taExibindoPropaganda: boolean;
  taExibindoRetrospectiva: boolean;
  usuarioAlteracaoSenha: string;
}

const initialState: GlobalState = {
  accessOldHonorarios: true,
  currentState: 'GER',
  dataTestId: '',
  exibirAlteracaoSenha: false,
  exibirJanelaProcessos: false,
  exibirTourReinf: false,
  globalParameter: null,
  headerDrawerToggle: false,
  headerSearchHover: false,
  isCanary: false,
  isEnvio: false,
  menuModulosMobile: false,
  modoExibicaoRetrospectiva: 1,
  primeiraVezTourReinf: true,
  retrospectiva: { jaVisualizou: false, podeExibir: false },
  showModalPgDasIntegraContador: false,
  sidebarToggle: false,
  sidebarToggleMobile: false,
  systemVersion: '',
  taExibindoCertificadoVencido: false,
  taExibindoMensageria: false,
  taExibindoPropaganda: false,
  taExibindoRetrospectiva: false,
  usuarioAlteracaoSenha: null,
};

function getReferenciaFromModule(refAtual: string, abreviar = false) {
  let result = refToFormat(refAtual);
  if (result) {
    result = `${abreviar ? 'Ref.' : 'Referência Atual'}: ${result}`;
  } else {
    result = 'Sem referência';
  }
  return result;
}

export const referenciaFiscal = (globalParameter: GlobalParameter, currentState: string, abreviar = false): string => {
  let result = 'Sem referência';
  if (globalParameter && currentState) {
    switch (currentState) {
      case 'MCG':
        if (globalParameter.empresaMCG) {
          result = getReferenciaFromModule(globalParameter.empresaMCG.refAtual, abreviar);
        }
        break;
      case 'MCP':
        if (globalParameter.empresaMCP) {
          result = getReferenciaFromModule(globalParameter.empresaMCP.refPatrimonial, abreviar);
        }
        break;
      case 'MFP':
        if (globalParameter.empresaMFP) {
          result = getReferenciaFromModule(globalParameter.empresaMFP.referencia, abreviar);
        }
        break;
      case 'MLF':
        if (globalParameter.empresaMLF) {
          result = getReferenciaFromModule(globalParameter.empresaMLF.referenciaAtual, abreviar);
        }
        break;
      case 'MHO':
        if (globalParameter.administradora.administradoraMHO) {
          result = getReferenciaFromModule(globalParameter.administradora.administradoraMHO.referencia, abreviar);
        }
        break;
      default:
        result = `${abreviar ? 'Ref.' : 'Referência Atual'}: ${formatRef(currentDate(), MONTH_YEAR_PATTERN)}`;
        break;
    }
  }
  return result;
};

export const setGlobalParameter = createAction('SET_GLOBAL_PARAMETER');
export const setCurrentState = createAction('SET_CURRENT_STATE');
export const toggleSidebar = createAction('TOGGLE_SIDEBAR');
export const toggleMobileSidebar = createAction('TOGGLE_MOBILE_SIDEBAR');
export const openHeaderSearch = createAction('OPEN_HEADER_SEARCH');
export const closeHeaderSearch = createAction('CLOSE_HEADER_SEARCH');
export const toggleHeaderDrawer = createAction('TOGGLE_HEADER_DRAWER');
export const setSystemVersion = createAction('SET_SYSTEM_VERSION');
export const setIsCanary = createAction('SET_IS_CANARY');
export const setTaExibindoMensageria = createAction('SET_TA_EXIBINDO_MENSAGERIA');
export const setTaExibindoPropaganda = createAction('SET_TA_EXIBINDO_PROPAGANDA');
export const setTaExibindoRetrospectiva = createAction('SET_TA_EXIBINDO_RETROSPECTIVA');
export const abrirJanelaProcessos = createAction('ABRIR_JANELA_PROCESSOS');
export const fecharJanelaProcessos = createAction('FECHAR_JANELA_PROCESSOS');
export const abrirAlteracaoSenha = createAction('ABRIR_ALTERACAO_SENHA');
export const fecharAlteracaoSenha = createAction('FECHAR_ALTERACAO_SENHA');
export const setAccessOldHonorarios = createAction('ACCESS_OLD_HONORARIOS');
export const setAccessHonorariosNitrus = createAction('ACCESS_HONORARIOS_NITRUS');
export const toggleMenuModulosMobile = createAction('TOGGLE_MENU_MODULOS_MOBILE');
export const setTaExibindoCertificadoVencido = createAction('SET_TA_EXIBINDO_CERTIFICADO_VENCIDO');
export const toggleModalPgDasIntegraContador = createAction('TOGGLE_PGDAS_INTEGRA_CONTADOR');
export const abrirTourReinfPrimeiraVez = createAction('ABRIR_TOUR_REINF_PRIMEIRA_VEZ', dataTestId => ({ dataTestId }));
export const abrirTourReinf = createAction('ABRIR_TOUR_REINF', (dataTestId, isEnvio) => ({ dataTestId, isEnvio }));
export const fecharTourReinf = createAction('FECHAR_TOUR_REINF');
export const setRetrospectiva = createAction('SET_RETROSPECTIVA');

export const reducer = handleActions<GlobalState>(
  {
    [setGlobalParameter.toString()]: (state: GlobalState, action: any) => ({
      ...state,
      globalParameter: action.payload,
    }),
    [setCurrentState.toString()]: (state: GlobalState, action: any) => ({
      ...state,
      currentState: action.payload,
    }),
    [toggleSidebar.toString()]: (state: GlobalState) => ({
      ...state,
      sidebarToggle: !state.sidebarToggle,
      sidebarToggleMobile: false,
    }),
    [toggleMobileSidebar.toString()]: (state: GlobalState) => ({
      ...state,
      menuModulosMobile: false,
      sidebarToggle: false,
      sidebarToggleMobile: !state.sidebarToggleMobile,
    }),
    [openHeaderSearch.toString()]: (state: GlobalState) => ({
      ...state,
      headerSearchHover: true,
    }),
    [closeHeaderSearch.toString()]: (state: GlobalState) => ({
      ...state,
      headerSearchHover: false,
    }),
    [toggleHeaderDrawer.toString()]: (state: GlobalState) => ({
      ...state,
      headerDrawerToggle: !state.headerDrawerToggle,
    }),
    [toggleModalPgDasIntegraContador.toString()]: (state: GlobalState) => ({
      ...state,
      showModalPgDasIntegraContador: !state.showModalPgDasIntegraContador,
    }),
    [setSystemVersion.toString()]: (state: GlobalState, action: any) => ({
      ...state,
      systemVersion: action.payload,
    }),
    [setIsCanary.toString()]: (state: GlobalState, action: any) => ({
      ...state,
      isCanary: action.payload,
    }),
    [setTaExibindoMensageria.toString()]: (state: GlobalState, action: any) => ({
      ...state,
      taExibindoMensageria: action.payload,
      taExibindoPropaganda: false,
      taExibindoRetrospectiva: false,
    }),
    [setTaExibindoPropaganda.toString()]: (state: GlobalState, action: any) => ({
      ...state,
      taExibindoMensageria: false,
      taExibindoPropaganda: action.payload,
      taExibindoRetrospectiva: false,
    }),
    [setTaExibindoRetrospectiva.toString()]: (state: GlobalState, action: any) => ({
      ...state,
      modoExibicaoRetrospectiva: action.payload ? action.payload.modo : 1,
      taExibindoMensageria: false,
      taExibindoPropaganda: false,
      taExibindoRetrospectiva: !!action.payload,
    }),
    [abrirJanelaProcessos.toString()]: (state: GlobalState) => ({ ...state, exibirJanelaProcessos: true }),
    [fecharJanelaProcessos.toString()]: (state: GlobalState) => ({ ...state, exibirJanelaProcessos: false }),
    [abrirAlteracaoSenha.toString()]: (state: GlobalState, action: any) => ({
      ...state,
      exibirAlteracaoSenha: true,
      usuarioAlteracaoSenha: action.payload || null,
    }),
    [fecharAlteracaoSenha.toString()]: (state: GlobalState) => ({
      ...state,
      exibirAlteracaoSenha: false,
      usuarioAlteracaoSenha: null,
    }),
    [setAccessOldHonorarios.toString()]: (state: GlobalState) => ({
      ...state,
      accessOldHonorarios: false,
    }),
    [setAccessHonorariosNitrus.toString()]: (state: GlobalState) => ({
      ...state,
      accessOldHonorarios: true,
    }),
    [toggleMenuModulosMobile.toString()]: (state: GlobalState) => ({
      ...state,
      menuModulosMobile: !state.menuModulosMobile,
    }),
    [setTaExibindoCertificadoVencido.toString()]: (state: GlobalState, action: any) => ({
      ...state,
      taExibindoCertificadoVencido: action.payload,
      taExibindoMensageria: false,
      taExibindoPropaganda: false,
      taExibindoRetrospectiva: false,
    }),
    [abrirTourReinfPrimeiraVez.toString()]: (state: GlobalState, action: any) => ({
      ...state,
      dataTestId: action.payload,
      exibirTourReinf: true,
      primeiraVezTourReinf: true,
    }),
    [abrirTourReinf.toString()]: (state: GlobalState, action: any) => ({
      ...state,
      dataTestId: action.payload,
      exibirTourReinf: true,
      isEnvio: action.payload.isEnvio,
      primeiraVezTourReinf: false,
    }),
    [fecharTourReinf.toString()]: (state: GlobalState) => ({
      ...state,
      exibirTourReinf: false,
    }),
    [setRetrospectiva.toString()]: (state: GlobalState, action: any) => ({
      ...state,
      retrospectiva: action.payload,
    }),
  },
  initialState
);
